import { colors } from '/css';

const CircleExclamationSolid = ({
    color = colors.black,
    size = 18,
    ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
        <g clipPath="url(#clip0_5049_24682)">
            <path
                d="M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM9 4.5C9.46758 4.5 9.84375 4.87617 9.84375 5.34375V9.28125C9.84375 9.74883 9.46758 10.125 9 10.125C8.53242 10.125 8.15625 9.74883 8.15625 9.28125V5.34375C8.15625 4.87617 8.53242 4.5 9 4.5ZM7.875 12.375C7.875 12.0766 7.99353 11.7905 8.2045 11.5795C8.41548 11.3685 8.70163 11.25 9 11.25C9.29837 11.25 9.58452 11.3685 9.7955 11.5795C10.0065 11.7905 10.125 12.0766 10.125 12.375C10.125 12.6734 10.0065 12.9595 9.7955 13.1705C9.58452 13.3815 9.29837 13.5 9 13.5C8.70163 13.5 8.41548 13.3815 8.2045 13.1705C7.99353 12.9595 7.875 12.6734 7.875 12.375Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_5049_24682">
                <rect width={size} height={size} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CircleExclamationSolid;
