import { create } from 'zustand';
import { UserType } from '/types/userTypes';
import { persist } from 'zustand/middleware';
import { useInitialState } from './store.helpers';

interface UserStoreState {
    user?: UserType;
}

const initialState: UserStoreState = {
    user: undefined,
};

interface UserStoreStateMutators {
    setUser: (user: UserType) => void;
    clearUser: () => void;
}

interface UserStore extends UserStoreState, UserStoreStateMutators {}

export const usePersistedUserState = create<UserStore>()(
    persist(
        (set) => ({
            ...initialState,
            setUser: (user) => set({ user }),
            clearUser: () => set({ user: undefined }),
        }),
        {
            name: 'user',
        }
    )
);

export const useUserState = <T>(selector: (state: UserStore) => T): T =>
    useInitialState<UserStore, UserStoreState>(
        usePersistedUserState,
        initialState,
        selector
    );
