import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '/css';
import Button from '../Button';
import PointerIcon from '/components/icons/PointerIcon';

interface PureProps extends HTMLAttributes<HTMLDivElement> {
    postCode?: string;
    postCodePlace?: string;
    grey?: boolean;
}

const PostCodeButtonPure = ({
    postCode,
    postCodePlace,
    grey,
    ...rest
}: PureProps) => {
    return (
        <CodeButton
            size="small"
            variant="tertiary"
            icon={<PointerIcon color="currentColor" />}
            iconPosition="left"
            grey={grey}
            {...rest}
        >
            {postCode}
            {!!postCodePlace && `, ${postCodePlace}`}
        </CodeButton>
    );
};

export default PostCodeButtonPure;

const CodeButton = styled(Button)<{ grey?: boolean }>`
    ${(p) =>
        !p.grey &&
        css`
            border-color: ${colors.purpleLighter5};
            background-color: ${colors.purpleLighter6}!important;
            color: ${colors.purple}!important;

            svg {
                color: ${colors.purpleDarker2} !important;
            }
        `}

    ${(p) =>
        p.grey &&
        css`
            svg {
                color: ${colors.black} !important;
            }
        `}
`;
