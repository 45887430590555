import { ui, config, Button, _ } from './../';
import ButtonType from '../types/frontButtonType';

interface Props {
    buttons: ButtonType[];
    onChange: (buttons: ButtonType[]) => void;
    style?: React.CSSProperties;
}

export default function Buttons({ buttons, onChange, style = {} }: Props) {
    const { Actions, editMode } = ui();

    const addButton = () =>
        onChange([
            ...buttons,
            {
                id: '',
                url: '',
                slug: '',
                type: '',
                path: '',
                title: _('Button'),
                target: '_self',
                buttonSize: 'default',
                buttonType: 'primary',
            },
        ]);

    const changeButton = (index: number, button: ButtonType) =>
        onChange(buttons.map((b, i) => (i === index ? button : b)));

    const deleteButton = (index: number) =>
        onChange(buttons.filter((_, i) => i !== index));

    return (
        <>
            <div
                className={`buttons-container flex wrap ${
                    buttons.length || editMode ? `pt-32` : ``
                }`}
                style={{ ...style, border: '1px solid transparent' }}
            >
                {buttons.map((button, i) => (
                    <div key={i}>
                        <Button
                            className={`button button-type-${button.buttonType} button-size-${button.buttonSize}`}
                            value={button}
                            onChange={(button) => changeButton(i, button)}
                            onDelete={(_) => deleteButton(i)}
                            link={config.pageUrl(button)}
                            style={{
                                marginRight:
                                    i < buttons.length - 1 ? '16px' : '0px',
                            }}
                        >
                            {button.title}
                        </Button>
                    </div>
                ))}
            </div>
            <Actions label={_('Button')} onAdd={addButton} align="bottom" />
        </>
    );
}
