import { InputValidationRules } from '/components/ui/form/Input.types';

const required = (value: string) => {
    return value.trim() !== '';
};

const email = (value: string) => {
    const regexp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regexp.test(value);
};

const phone = (value: string) => {
    if (String(value).startsWith('+48'))
        return /^(0048|\+48|48)?\d{9}$/.test(value);
    return /^(0047|\+47|47)?\d{8}$/.test(value);
};

const regexp = (value: string, regexp: RegExp) => regexp.test(value);

const min = (value: string | number, number: number) =>
    (typeof value === 'string' ? value.length : value) >= number;

const max = (value: string | number, number: number) =>
    (typeof value === 'string' ? value.length : value) <= number;

const validatedValue = (value: string, rules: InputValidationRules) => {
    let validated = value;

    if (rules.integer) {
        validated = value.replace(/[\D]/g, '');
    }

    if (rules.float) {
        validated = value
            .replace(',', '.')
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1');
    }

    if (value && rules.maxLength) {
        validated = value.substring(0, rules.maxLength);
    }

    if (rules.phone) {
        validated = value.replace(/[^\d+]/g, '');
    }

    return validated;
};

const validate = (
    value: string,
    rules: InputValidationRules
): true | string[] => {
    let validationErrors: string[] = [];

    const val = validatedValue(value, rules);
    const isNumberValue = rules.float || rules.integer;

    if (rules.required && !required(val)) {
        validationErrors.push('Må fylles ut');
    }

    if (rules.email && !email(val)) {
        validationErrors.push('Ugyldig e-post');
    }

    if (rules.phone && !phone(val)) {
        validationErrors.push('Ugyldig telefonnummer');
    }

    if (rules.regexp && !regexp(val, rules.regexp)) {
        validationErrors.push('Ugyldig format');
    }

    if (rules.min && !min(isNumberValue ? Number(val) : val, rules.min)) {
        validationErrors.push(
            `Må være minst ${rules.min} ${!isNumberValue ? 'tegn' : ''}`
        );
    }

    if (rules.max && !max(isNumberValue ? Number(val) : val, rules.max)) {
        validationErrors.push(
            `Må være maks ${rules.max} ${!isNumberValue ? 'tegn' : ''}`
        );
    }

    return !validationErrors.length ? true : validationErrors;
};

export interface ValidationErrors {
    [key: string]: string[];
}

const validateFields = (
    fields: [key: string, value: any, rules: InputValidationRules][]
): true | ValidationErrors => {
    const errors: ValidationErrors = {};
    fields.forEach(([key, value, rules]) => {
        const validation = validate(value, rules);
        if (validation !== true) {
            errors[key] = validation;
        }
    });

    if (!Object.keys(errors).length) {
        return true;
    }

    return errors;
};

const validators = {
    required,
    email,
    phone,
    regexp,
    min,
    max,
    validate,
    validateFields,
};

export default validators;
