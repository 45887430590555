const colors = {
    black: '#1F1A22',
    blackLight: '#373737',
    white: '#FFFFFF',
    grey: '#95939F',
    grey1: '#F7F7FA',
    grey2: '#F2F2F5',
    grey3: '#F7F7FA',
    grey7: '#4A4A4D',
    subHeading: '#6A6A76',
    lightGrey: '#ECEDF0',
    featherGrey: '#F5F5F7',
    blueGrey: '#F5F5F7',

    purple: '#5E43FD',
    purpleLight: '#7E69FD',
    purpleLighter: '#EBE8FE',
    purpleLighter2: '#8E7BFE',
    purpleLighter3: '#AEA1FE',
    purpleLighter4: '#CEC7FA',
    purpleLighter5: '#DFD9FF',
    purpleLighter6: '#ECE8FF',
    purpleLighter7: '#F3F2FE',
    purple32: '#CCC3FE',
    purple12: '#EAE8FD',
    purpleDarker2: '#5039D8',
    purpleDarker3: '#4833C2',
    purpleDarker4: '#3C2AA6',
    purpleDarker5: '#2E1F83',
    purpleDarker6: '#222058',
    purpleDarker7: '#120F36',
    purpleM1: '#765FFD',
    purpleM7: '#F4F2FF',

    orange: '#FF6400',
    orangeLight: '#FEDCCD',
    orangeLighter: '#FFF4EF',
    orange25: '#ffd8bf',
    orangeM7: '#FFF3ED',

    mint: '#44D7CB',
    mintLight: '#C3F2EE',
    mintLighter: '#EBF9F8',
    mintLighter5: '#DFF7F4',
    mintM7: '#F2FCFB',

    green: '#74F25A',
    greenLight: '#DCFCD6',
    greenLighter: '#EEFDEB',
    green25: '#DCFBD5',

    red: '#EC3128',

    whiteTransparent: 'rgba(255,255,255,.5)',

    yellowLight: '#FDF2BB',
    yellowLighter4: '#FBF7DB',
    yellowM4: '#FCF7D7',
    yellowP1: '#F3DA29',

    textDimmed: '#8A8A99',

    // old colors - those will need to be replace with one of new ones above
    lightBlue: '#001fff',
    lighterBlue: '#00d0ff',
    lightGray: '#eaeaea',
    blue: '#090fde',
    blueLighter5: '#DFE8F8',
    blueM6: '#E8EFFA',
    blueM4: '#C2DAFF',
    blueM3: '#9FC4FD',

    statuses: {
        process: {
            primary: '#CCC3FE',
            primaryText: '#1F1A22',
            secondary: '#EBE8FE',
            secondaryText: '#4837C2',
            secondaryTextDark: '#2D237A',
        },
        error: {
            primary: '#FF6400',
            primaryText: '#1F1A22',
            secondary: '#FFD8BF',
            secondaryText: '#AB2F02',
            secondaryTextDark: '#6C1D00',
        },
        action: {
            primary: '#44D7CB',
            primaryText: '#1F1A22',
            secondary: '#C3F2EE',
            secondaryText: '#079589',
            secondaryTextDark: '#02655D',
        },
        completed: {
            primary: '#74F25A',
            primaryText: '#1F1A22',
            secondary: '#EEFDEB',
            secondaryText: '#4FA33E',
            secondaryTextDark: '#28581E',
        },
        cancelled: {
            primary: '#95939F',
            primaryText: '#ffffff',
            secondary: '#F5F5F7',
            secondaryText: '#6A6A76',
            secondaryTextDark: '#090718',
        },
    },
    divider: `rgba(76, 71, 108, 0.08)`,
};

export default colors;
