import fromPairs from 'ramda/src/fromPairs';
import map from 'ramda/src/map';
import toPairs from 'ramda/src/toPairs';
import is from 'ramda/src/is';
import reduce from 'ramda/src/reduce';
import QuickLRU from 'quick-lru';

interface Config {
    deep: boolean;
    exclude?: string[]; // keys to exclude
    stopAt?: string[]; // keys to stop deep conversion at
}

type Object = Record<string, any>;

const cache = new QuickLRU<string, string>({ maxSize: 1000 });

const toCamelCase = (s: string): string => {
    if (cache.has(s)) {
        return cache.get(s)!;
    }

    let result = s.replace(/([-_][a-z])/gi, ($1) =>
        $1.toUpperCase().replace('-', '').replace('_', '')
    );

    /*
    // if result is all caps - convert to lower case
    if(/^[A-Z]+$/.test(result)) {
        result = result.toLowerCase()
    } else {
        result = result.charAt(0).toLowerCase() + result.slice(1);
    }*/
    result = result.charAt(0).toLowerCase() + result.slice(1);

    cache.set(s, result);
    return result;
};

// transform object keys to camelCase, excluding specified keys
const keysToCamelCase = (obj: Object, exclude: string[] = []): any =>
    fromPairs(
        map(
            ([k, v]) => [exclude.includes(k) ? k : toCamelCase(k), v],
            toPairs(obj)
        )
    );

// deeply transform object keys (including arrays), excluding specified keys and stopping at specified keys
const deepTransformKeys = (
    obj: any,
    exclude: string[] = [],
    stopAt: string[] = []
): any => {
    if (is(Array, obj)) {
        return map((item) => deepTransformKeys(item, exclude, stopAt), obj);
    }
    if (is(Object, obj)) {
        return reduce(
            (acc: Object, [k, v]: [string, any]) => {
                const key = exclude.includes(k) ? k : toCamelCase(k);
                acc[key] = stopAt.includes(k)
                    ? v
                    : is(Object, v) || is(Array, v)
                    ? deepTransformKeys(v, exclude, stopAt)
                    : v;
                return acc;
            },
            {},
            toPairs(obj)
        );
    }
    return obj;
};

const camelcaseKeys = (
    input: Object | Object[],
    config: Config = { deep: false }
): any => {
    const { deep, exclude, stopAt } = config;
    if (deep) {
        return deepTransformKeys(input, exclude, stopAt);
    }
    if (Array.isArray(input)) {
        return input.map((item) => keysToCamelCase(item, exclude));
    }
    return keysToCamelCase(input, exclude);
};

export default camelcaseKeys;
