import styled from 'styled-components';
import { InputProps } from '../Input';
import Icons from '../../Icon';
import { colors } from '/css';

interface Props
    extends Pick<
        InputProps,
        'invalid' | 'valid' | 'readOnly' | 'clearable' | 'suffix' | 'value'
    > {
    onClear?: () => void;
}

const InputSuffix = ({
    invalid,
    valid,
    readOnly,
    clearable,
    suffix,
    value,
    onClear,
    ...props
}: Props) => {
    const isClearable =
        clearable === 'always' || (clearable && !readOnly && !!value);
    return (
        <Component {...props}>
            {!invalid && suffix}
            {invalid && !valid && <Icons icon="error" />}
            {valid && <Icons icon="check" fill={colors.green} />}
            {isClearable && (
                <button
                    onClick={onClear}
                    className="clear-button"
                    aria-label="Clear input"
                >
                    <Icons icon="empty-field" />
                </button>
            )}
        </Component>
    );
};

const Component = styled.div`
    font-size: 17px;
    font-family: var(--font-semibold);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    display: flex;

    .button {
        padding: 5px;

        svg {
            display: block;
        }
    }
`;

export default InputSuffix;
