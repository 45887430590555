import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import Script from 'next/script';

import '/node_modules/@glidejs/glide/dist/css/glide.core.min.css';
import '/node_modules/@glidejs/glide/dist/css/glide.theme.min.css';

import '/css/fonts.css';
import '/css/shepherd.scss';
import GlobalStyles from '/css/GlobalStyles';
import { cookie, goTo, isSupported, pathNameIs } from '/lib/helpers';
import bugsnagClient from '/lib/bugsnag';
import Error from '/pages/_error';
import {
    permissionsFromConsent,
    pushNotCustomerUser,
    setCookieConsent,
} from '/lib/ga-data-layer';
import { useOrderState } from '/store/orderStore';
import { useUserState } from '/store/userStore';
import { useOnboardingState } from '/store/onboardingStore';
import useUserIs from '/hooks/useUserIs';
import LoginModalProvider from '/components/features/login-modal/LoginModal.provider';
import usePostCodeState from '/hooks/usePostCodeState';
import postCodeApi from '/api/postCode';

const CookieBar = dynamic(() => import('/components/CookieBar'), {
    ssr: false,
});

const ErrorBoundary = bugsnagClient.getPlugin('react');

const gtmTrackingCode =
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
        ? 'GTM-NXK9DKK'
        : 'GTM-PS7GSCC';

export default function App({ Component, pageProps, router }) {
    const clearOrderCart = useOrderState((state) => state.clearOrderCart);

    const user = useUserState((state) => state.user);
    const userIs = useUserIs(user);
    const is = pathNameIs(router.pathname);

    const resetOnboardingState = useOnboardingState(
        (state) => state.resetOnboardingState
    );

    const resetOrder = useOrderState((state) => state.resetOrder);

    useEffect(() => {
        if (user && !userIs.customer) {
            pushNotCustomerUser(user);
        }
    }, [user?.id, userIs.customer]);

    useEffect(() => {
        checkVersions();
        enhanceHtmlLinks();
        unregisterServideWorkers();

        const consentCookie = cookie.get('CookieConsent');
        const consentPermissionsCookie = cookie.get('CookieConsentPermissions');

        // This needs to be here for 2 years until 28. Jun 2025, to make sure cookie consent works correctly for
        // existing users that only have the CookieConsent and not the CookieConsentPermissions cookie yet
        if (consentCookie && !consentPermissionsCookie) {
            cookie.set(
                'CookieConsentPermissions',
                permissionsFromConsent(
                    consentCookie === 'all'
                        ? ['essentials', 'others']
                        : ['essentials']
                ),
                365
            );
        }

        if (consentCookie)
            setCookieConsent(
                consentCookie === 'all'
                    ? ['essentials', 'others']
                    : ['essentials']
            );
    }, []);

    useEffect(() => {
        setupBodyClasses();
    }, [router.pathname]);

    const {
        postCodeStateHydrated,
        postCode,
        setPostCodePlace,
        setPostCodeValid,
        setPostCodeSupportedJobTypes,
    } = usePostCodeState();

    useEffect(() => {
        if (
            postCodeStateHydrated &&
            postCode &&
            !is.backend &&
            !userIs.salesPartnerSeller
        ) {
            (async () => {
                const response = await postCodeApi.checkPostCode(postCode);
                if ('error' in response) {
                    setPostCodeValid(false);
                    setPostCodeSupportedJobTypes([]);
                    setPostCodePlace('');
                } else {
                    setPostCodeValid(true);
                    setPostCodeSupportedJobTypes(response.data.jobTypes || []);
                    setPostCodePlace(response.data.place);
                }
            })();
        }
    }, [postCodeStateHydrated]);

    const unregisterServideWorkers = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                    for (let registration of registrations) {
                        registration.unregister();
                    }
                });
        }
    };

    const setupBodyClasses = () => {
        const is = pathNameIs(router.pathname);
        let classes = Object.keys(is)
            .filter((k) => is[k])
            .map((k) => 'is-' + k);

        if (user) {
            const roleClasses = user.activeRoles.map((r) => 'is-' + r);
            classes = [...classes, ...roleClasses];
        }

        // indicate to test runner that js is ready
        const nextWrapper = document.getElementById('__next');
        if (nextWrapper) nextWrapper.className = 'is-ready';

        if (document.body.className !== classes.join(' '))
            document.body.className = classes.join(' ');
    };

    const checkVersions = () => {
        if (!isSupported(() => window.localStorage)) return;

        const appVersionUpdated = window.localStorage.getItem(
            'spoton-app-version-updated'
        );
        const cartVersionUpdated = window.localStorage.getItem(
            'spoton-cart-version-updated'
        );

        if (appVersionUpdated) {
            resetOnboardingState();
            resetOrder();
            window.localStorage.removeItem('spoton-app-version-updated');
        }

        if (cartVersionUpdated) {
            clearOrderCart();
            window.localStorage.removeItem('spoton-cart-version-updated');
        }
    };

    const enhanceHtmlLinks = () => {
        document.addEventListener(
            'click',
            function (e) {
                const dataPage = e.target.getAttribute('data-page');
                if (dataPage) {
                    e.preventDefault();
                    goTo(e.target.getAttribute('href'));
                }
            },
            false
        );
    };

    return (
        <>
            {process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local' && (
                <>
                    <Script
                        id="gtm-script"
                        dangerouslySetInnerHTML={{
                            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${gtmTrackingCode}');`,
                        }}
                    />
                    <Script
                        id="gtm-consent-listener"
                        dangerouslySetInnerHTML={{
                            __html: `
                            const consentListeners = [];
                            window.addConsentListener = (callback) => {
                              consentListeners.push(callback);
                            };
                            const onConsentChange = (consent) => {
                              consentListeners.forEach((callback) => {
                                callback(consent);
                              });
                            };
                            `,
                        }}
                    />
                    <noscript
                        dangerouslySetInnerHTML={{
                            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmTrackingCode}"
                        height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                        }}
                    />
                </>
            )}
            <ErrorBoundary FallbackComponent={Error}>
                <LoginModalProvider>
                    <Component {...pageProps} />
                </LoginModalProvider>
                <CookieBar />
            </ErrorBoundary>
            <GlobalStyles />
        </>
    );
}
