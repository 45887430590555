import { css } from 'styled-components';
import { ButtonStyleProps } from './Button.types';
import newButtonVariants from './newButtonVariants';
import { colors } from '/css';

const buttonVariants = {
    primary: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        color: white;
        background: ${colors.purple};
        //border-color: ${colors.purple};

        @media (pointer: fine) {
            &:hover {
                background: ${colors.purple};
                color: white;
            }
        }
        &:visited,
        &:active {
            background: ${colors.purple};
            color: white;
        }

        .button-icon svg {
            color: white;
        }
    `,
    secondary: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        background: ${colors.black};
        color: white;
        @media (pointer: fine) {
            &:hover {
                background: ${colors.black};
                color: white;
            }
        }
        &:visited,
        &:active {
            background: ${colors.black};
            color: white;
        }

        .button-icon svg {
            color: white;
        }
    `,
    'secondary-inverted': (p: ButtonStyleProps) => css<ButtonStyleProps>`
        background: ${colors.white};
        color: ${colors.black};
        @media (pointer: fine) {
            &:hover {
                background: ${colors.white};
            }
        }
        &:visited,
        &:active {
            background: ${colors.white};
        }
    `,
    tertiary: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        background: transparent;
        color: ${colors.black};
        border: 3px solid rgba(76, 71, 108, 0.08);

        @media (pointer: fine) {
            &:hover {
                border-color: ${colors.purple};
                background: transparent;
                color: ${colors.black};
            }
        }

        &.active,
        &:active {
            border-color: ${colors.purple};
            color: ${colors.black};
            background: transparent;
        }

        &:visited {
            color: ${colors.black};
        }

        .button-icon svg {
            color: ${colors.black};
        }

        ${(p) =>
            p.raised &&
            css`
                box-shadow: 0 6px 24px rgba(0, 0, 0, 0.06),
                    0 2px 6px rgba(31, 26, 34, 0.06);
                border-color: transparent;

                @media (pointer: fine) {
                    &:hover {
                        border-color: transparent;
                        box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12),
                            0 2px 6px rgba(31, 26, 34, 0.06);
                    }
                }

                &.active,
                &:active {
                    border-color: transparent;
                    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12),
                        0 2px 6px rgba(31, 26, 34, 0.06);
                }
            `}

        ${(p) =>
            p.manuallyDisabled &&
            css`
                background: transparent !important;
                @media (pointer: fine) {
                    &:hover,
                    &:focus {
                        border-color: rgba(76, 71, 108, 0.08);
                        background: transparent !important;
                    }
                }
            `}
    `,
    'tertiary-inverted': (p: ButtonStyleProps) => css<ButtonStyleProps>`
        background: transparent;
        color: ${colors.white};
        border: 3px solid rgba(255, 255, 255, 0.4);

        &:hover {
            background-color: rgba(255, 255, 255, 0.03);
        }

        &:visited {
            color: ${colors.white};
        }

        .button-icon svg {
            color: ${colors.white};
        }
    `,
    soft: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        background: ${colors.purpleLighter6};
        color: ${colors.purpleDarker3};
        border: 3px solid transparent;
        @media (pointer: fine) {
            &:hover {
                border-color: transparent;
                background: ${colors.purpleLighter5};
                color: ${colors.purpleDarker3};
            }
            :focus {
                border-color: #fff;
                outline: 3px solid #cfc7fe;
            }
        }
        &.active,
        &:active {
            opacity: 0.8;
        }
        &:visited {
            color: ${colors.purpleDarker3};
        }
        ${(p) =>
            p.manuallyDisabled &&
            css`
                background: rgba(0, 0, 0, 0.05) !important;
                color: rgba(0, 0, 0, 0.5) !important;
                opacity: 1 !important;
            `}
    `,
    orange: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        background: ${colors.orange};
        color: white;
        border-color: ${colors.orange};
        @media (pointer: fine) {
            &:hover {
                background: ${colors.orange};
                color: white;
            }
        }
        &:visited,
        &:active {
            background: ${colors.orange};
            color: white;
        }

        .button-icon svg {
            color: white;
        }
    `,
    black: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        background: ${colors.black};
        color: white;
        @media (pointer: fine) {
            &:hover {
                background: ${colors.black};
                color: white;
            }
        }
        &:visited,
        &:active {
            background: ${colors.black};
            color: white;
        }

        .button-icon svg {
            color: white;
        }
    `,
    grey: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        background: ${colors.grey2};
        color: ${colors.black};

        @media (pointer: fine) {
            &:hover {
                background: ${colors.purple};
                color: white;
                svg {
                    color: white;
                }
            }
        }
        &:visited,
        &:active {
            background: ${colors.purple};
            color: white;

            svg {
                color: white;
            }
        }
    `,
};

export const getButtonVariant = (newStyle = false) => {
    return newStyle ? newButtonVariants : buttonVariants;
};

export default buttonVariants;
