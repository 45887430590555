import {
    useSelected,
    useFocused,
    RenderElementProps,
    RenderLeafProps,
} from 'slate-react';
import { Text } from 'slate';
import config from '../../../config';
import ImageType from '../../../types/frontImageType';

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
    <span contentEditable={false} style={{ fontSize: '0' }}>
        ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
);

export const srcSet = (url: string) => {
    const imgUrl = new URL(url);
    const hasQueryParams = imgUrl.search.length > 1;
    const queryGlue = hasQueryParams ? '&' : '?';
    const isSvg = /\.svg(?:\?.*)?$/i.test(String(url));

    if (isSvg) return url;

    return `${url}${queryGlue}dpr=1 1x, ${url}${queryGlue}dpr=2 2x`;
};

const ImageElement = ({
    element,
    editorMaxWidth,
    attributes,
    children,
}: {
    element: RenderElementProps['element'];
    editorMaxWidth: number;
    attributes: RenderElementProps['attributes'];
    children: RenderElementProps['children'];
}) => {
    const selected = useSelected();
    const focused = useFocused();

    if (!element.url) return null;

    const width = element.width
        ? editorMaxWidth * element.width
        : editorMaxWidth;

    const src = element.metadata
        ? config
              .imageUrl(element as ImageType)
              .width(width)
              .url()
        : element.url || '';

    return (
        <div {...attributes}>
            {children}
            <div
                contentEditable={false}
                className={[
                    'ft-rich-text-image-wrapper',
                    selected && focused && 'ft-focused',
                    element.float && `ft-img-float-${element.float}`,
                ]
                    .filter((c) => c)
                    .join(' ')}
            >
                <img
                    src={src}
                    srcSet={srcSet(src)}
                    alt={element.alt || 'image'}
                />
            </div>
        </div>
    );
};

/* eslint-disable react/display-name */
export const renderElement =
    (editorMaxWidth: number) =>
    ({ attributes, element, children }: RenderElementProps) => {
        const style = { textAlign: element.align };

        switch (element.type) {
            case 'link':
                return (
                    <a {...attributes} href={element.data?.url || '#'}>
                        <InlineChromiumBugfix />
                        {children}
                        <InlineChromiumBugfix />
                    </a>
                );
            case 'bulleted-list':
                return (
                    <ul style={style} {...attributes}>
                        {children}
                    </ul>
                );
            case 'h1':
                return (
                    <h1 style={style} {...attributes}>
                        {children}
                    </h1>
                );
            case 'h2':
                return (
                    <h2 style={style} {...attributes}>
                        {children}
                    </h2>
                );
            case 'h3':
                return (
                    <h3 style={style} {...attributes}>
                        {children}
                    </h3>
                );
            case 'h4':
                return (
                    <h4 style={style} {...attributes}>
                        {children}
                    </h4>
                );
            case 'list-item':
                return (
                    <li style={style} {...attributes}>
                        {children}
                    </li>
                );
            case 'numbered-list':
                return (
                    <ol style={style} {...attributes}>
                        {children}
                    </ol>
                );
            case 'image':
                return (
                    <ImageElement
                        element={element}
                        editorMaxWidth={editorMaxWidth}
                        attributes={attributes}
                    >
                        {children}
                    </ImageElement>
                );

            default:
                return <p {...attributes}>{children}</p>;
        }
    };
/* eslint-enable react/display-name */

export interface CustomLeaf extends Text {
    bold?: boolean;
    italic?: boolean;
    underlined?: boolean;
}

export const renderLeaf = ({
    attributes,
    children,
    leaf: _leaf,
}: RenderLeafProps) => {
    const leaf = _leaf as CustomLeaf;

    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underlined) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};
