import api from './api';

const settingsApi = {
    all: (token?: string) => api.getSecure('settings', token),

    update: (settings: any) => api.patch('settings', settings),

    updateUserSettings: (data: any) => api.patch(`user`, data),
};

export default settingsApi;
