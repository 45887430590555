import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CompanyInfo } from '/types/projectType';
import { useInitialState } from './store.helpers';
import { UserType } from '/types/userTypes';

interface OnboardingStoreState {
    step: number;
    company: CompanyInfo;
    contractorUser: UserType | null;
}

const initialState: OnboardingStoreState = {
    step: 1,
    company: {
        organizationNumber: '',
        name: '',
        address: '',
        partnerId: '',
        postArea: '',
        postCode: '',
    },
    contractorUser: null,
};

interface OnboardingStateMutators {
    resetOnboardingState: () => void;
    setOnboardingStep: (step: number) => void;
    setContractorCompany: (company: CompanyInfo) => void;
    setContractorUser: (user: UserType) => void;
}

interface OnboardingStore
    extends OnboardingStoreState,
        OnboardingStateMutators {}

export const usePersistedOnboardingState = create<OnboardingStore>()(
    persist(
        (set) => ({
            ...initialState,
            resetOnboardingState: () => set({ ...initialState }),
            setOnboardingStep: (step: number) => set({ step }),
            setContractorCompany: (company: CompanyInfo) => set({ company }),
            setContractorUser: (contractorUser: UserType) =>
                set({ contractorUser }),
        }),
        {
            name: 'onboarding',
        }
    )
);

export const useOnboardingState = <T>(
    selector: (state: OnboardingStore) => T
): T =>
    useInitialState<OnboardingStore, OnboardingStoreState>(
        usePersistedOnboardingState,
        initialState,
        selector
    );
