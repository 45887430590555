import isRetina from "./isRetina";

const imgixUrl = (url: any, { width }: {width: number}) => {
    if (!url) return '';

    if (url.indexOf('?')) url = url.split('?')[0];

    return `${url}?w=${width || 1120}&fm=jpg&auto=format&dpr=${
        isRetina() ? 2 : 1
    }`;
};

export default imgixUrl;