import { colors } from '/css';

const CircleCheckSolid = ({ color = colors.black, size = 18, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
        <g clipPath="url(#clip0_5049_23979)">
            <path
                d="M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM12.9727 7.34766L8.47266 11.8477C8.14219 12.1781 7.60781 12.1781 7.28086 11.8477L5.03086 9.59766C4.70039 9.26719 4.70039 8.73281 5.03086 8.40586C5.36133 8.07891 5.8957 8.07539 6.22266 8.40586L7.875 10.0582L11.7773 6.15234C12.1078 5.82188 12.6422 5.82188 12.9691 6.15234C13.2961 6.48281 13.2996 7.01719 12.9691 7.34414L12.9727 7.34766Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_5049_23979">
                <rect width={size} height={size} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CircleCheckSolid;
