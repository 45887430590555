import Button from '../components/ui/buttons/Button';
import Link from '/components/ui/Link';
import Config from './types/frontConfigType';
import menuApi from '/api/menu';
import ImageUrlBuilder from '/front/lib/ImageUrlBuilder';
import Page, { PageTypes, PartialPage } from '/front/types/frontPageType';
import { usePageState } from '/store/pageStore';
import { userIs } from '/lib/helpers';
import { useUserState } from '/store/userStore';
import { UserRole } from '/types/userTypes';
import draftApi from '/api/cms/frontDraftApi';
import mediaApi from '/api/cms/frontMediaApi';
import settingsApi from '/api/cms/frontSettingsApi';
import moduleTemplateApi from '/api/cms/frontModuleTemplateApi';
import pageApi from '/api/cms/frontPageApi';
import categoryApi from '/api/cms/frontCategoryApi';

const config: Config = {
    useUser: () => {
        const user = useUserState((state) => state.user);
        const env = process.env.NEXT_PUBLIC_ENVIRONMENT;
        const isLocalOrStaging = env === 'local' || env === 'staging';
        const isEditor = userIs(user, UserRole.GLOBAL_ADMIN);

        if (!user && isLocalOrStaging) {
            return {
                user,
                canSeeAdminBar: true,
                canEditPages: false,
                canUseRichText: false,
            };
        }
        return {
            user: user as any,
            canSeeAdminBar: isEditor,
            canEditPages: isEditor,
            canUseRichText: userIs(user, [
                UserRole.GLOBAL_ADMIN,
                UserRole.CUSTOMER_SERVICE,
                UserRole.CASEWORKER,
            ]),
        };
    },
    usePage: () => {
        const page = usePageState((state) => state.page) as Page | null;
        const setPage = usePageState((state) => state.setPage);
        return {
            page,
            setPage: (data: PartialPage | null) => setPage(data),
        };
    },
    pageUrl: (page) => ({
        href: !page
            ? '#'
            : page.url
            ? page.url
            : page._type === 'job'
            ? `/bestilling/jobb/${page.slug}`
            : page._type === PageTypes.MODULE_TEMPLATE
            ? `/module-templates/${page.id}`
            : page.path
            ? page.path
            : `/${page.slug}`,
    }),
    imageUrl: (image) => new ImageUrlBuilder(image),
    isNewPage: (path = '') => {
        return path === '/new' || path === '/module-templates/new';
    },
    api: {
        page: {
            get: pageApi.get,
            getWithMeta: pageApi.getWithMeta,
            linkSearch: pageApi.linkSearch,
            post: pageApi.post,
            patch: pageApi.patch,
            delete: pageApi.delete,
            renderModule: pageApi.renderModule,
        },
        moduleTemplate: {
            get: moduleTemplateApi.index,
            single: moduleTemplateApi.single,
            post: moduleTemplateApi.create,
            patch: moduleTemplateApi.update,
            delete: moduleTemplateApi.delete,
        },
        draft: {
            get: draftApi.get,
            post: draftApi.post,
            patch: draftApi.patch,
            delete: draftApi.delete,
        },
        media: {
            get: mediaApi.get,
            put: mediaApi.put,
            patch: mediaApi.patch,
            delete: mediaApi.delete,
        },
        category: {
            get: categoryApi.get,
            post: categoryApi.post,
            patch: categoryApi.patch,
            patchOrder: categoryApi.patchOrder,
            delete: categoryApi.delete,
        },
        settings: {
            get: settingsApi.get,
            patch: settingsApi.patch,
        },
        menu: {
            get: menuApi.get,
            patch: menuApi.patch,
        },
    },
    ui: {
        ColorPicker: {
            options: [
                '#FF6400', // Orange
                '#FFD8BF', // Orange 25 %
                '#FFF3EB', // Orange 8 %
                '#5E43FD', // Purple
                '#7E69FD', // purple 80 %
                '#BFB8EF', // Purple 38 %
                '#EBE8FE', // Purple 8 %
                '#44D7CB', // Mint
                '#C3F2EE', // Mint 32 %
                '#EBF9F8', // mint 12 %
                '#74F25A', // Green
                '#DCFBD5', // Green 25%
                '#EEFDEB', // Green 12 %
                '#94939E', // Grey
                '#ECEDF0', // Light Grey
                '#F5F5F7', // Feather Grey
                '#ffffff', // White
                '#1E1A21', // Black
                '#FDF2BB', // yellow light
            ],
        },
        RichTextEditor: {
            options: {
                storeInternalLinksAsReference: false, // set to false when not working with Sanity backen
            },
        },
    },
    presentation: {
        Button: {
            buttonComponent: Button, // component used to render buttons in modules
        },
        Link: {
            linkComponent: Link, // component used to render links in modules
        },
    },
    mediaCategories: [
        { name: 'product', value: 'product' },
        { name: 'illustration', value: 'illustration' },
        { name: 'picture', value: 'picture' },
        { name: 'icon', value: 'icon' },
    ],
    pageTypes: [{ name: 'page', value: 'page' }],
    categoryTypes: [{ name: 'Job categories', value: 'job-category' }],
    translations: {},
};

export default config;
