import styled from 'styled-components';
import { colors } from '/css';

interface Props {
    errors?: string[];
}

const InputValidationErrors = ({ errors }: Props) => {
    if (!errors?.length) {
        return null;
    }

    return (
        <Component>
            {errors.map((err, i) => (
                <li key={`${i}-${err}`}>
                    {err}
                    {i < errors.length - 1 ? `, ` : ``}
                </li>
            ))}
        </Component>
    );
};

const Component = styled.ul`
    all: unset;
    font-size: 0.8em;
    color: ${colors.orange};

    li {
        all: unset;
    }
`;

export default InputValidationErrors;
