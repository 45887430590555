import { isServer } from '/lib/helpers';

const dataLayerPush = (data) => {
    if (isServer) return;
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
        console.log(`Data layer push "${data.event}"`, data);
    }
    (window.dataLayer || []).push(data);
};

const stringifyJobCategory = (job) => {
    if (!job) return '';

    const categories =
        job?.data?.jobTypeVersion?.categories || job.categories || [];
    return categories[0]?.name || '';
};

const jobToItemData = (job, index = 0) => ({
    item_id: job?.data?.jobTypeId || job?.jobTypeId || '',
    item_name: job?.name || job?.jobTypeVersion?.name || '',
    item_category: stringifyJobCategory(job),
    price:
        job?.total?.gross ||
        job?.totals?.startingPrice ||
        job?.startingPrice?.gross ||
        0,
    index: index,
});

export const permissionsFromConsent = (categories) => {
    const consentTypes = {
        essentials: [
            'functionality_storage',
            'personalization_storage',
            'security_storage',
        ],
        others: [
            'ad_storage',
            'analytics_storage',
            'ad_user_data',
            'ad_personalization',
        ],
    };

    let permissions = [];

    categories.forEach((category) => {
        permissions.push(...consentTypes[category]);
    });

    return permissions;
};

export const setCookieConsent = (categories) => {
    const permissions = permissionsFromConsent(categories);

    // onConsentChange is a global method defined in _app.js that GTM listens to
    if (typeof onConsentChange !== 'undefined') onConsentChange(permissions);
};

export const trackCookieConsentChoice = (choice) => {
    dataLayerPush({
        event: 'cookie_consent_choice',
        choice: choice,
    });
};

const listNameToListId = (listName) => {
    return String(listName).toLowerCase().replace(/\s/g, '-');
};

export const trackViewItemList = (jobs, listName) => {
    if (!jobs) return;

    dataLayerPush({ ecommerce: null });
    dataLayerPush({
        event: 'view_item_list',
        ecommerce: {
            currency: 'NOK',
            item_list_id: listNameToListId(listName),
            item_list_name: listName,
            items: jobs.map((job, index) => jobToItemData(job, index)),
        },
    });
};

export const trackViewItem = (job) => {
    dataLayerPush({ ecommerce: null });
    dataLayerPush({
        event: 'view_item',
        ecommerce: {
            currency: 'NOK',
            items: [jobToItemData(job)],
        },
    });
};

export const trackSelectItem = (job, listName, listIndex) => {
    dataLayerPush({ ecommerce: null });
    dataLayerPush({
        event: 'select_item',
        ecommerce: {
            currency: 'NOK',
            item_list_id: listNameToListId(listName),
            item_list_name: listName,
            items: [jobToItemData(job, listIndex)],
        },
    });
};

export const trackAddToCart = (job, totalCost) => {
    dataLayerPush({ ecommerce: null });
    dataLayerPush({
        event: 'add_to_cart',
        ecommerce: {
            currency: 'NOK',
            value: totalCost?.gross,
            items: [
                {
                    item_id: job?.jobTypeId,
                    item_name: job?.jobTypeversion?.name,
                    item_category:
                        job?.jobTypeVersion?.categories[0].name || '',
                    price: totalCost?.gross,
                    index: 0,
                },
            ],
        },
    });
};

export const trackRemoveFromCart = (jobs) => {
    const totalValue = jobs.reduce((total, job) => total + job.total.gross, 0);

    dataLayerPush({ ecommerce: null });
    dataLayerPush({
        event: 'remove_from_cart',
        ecommerce: {
            currency: 'NOK',
            value: totalValue,
            items: jobs.map((job, index) => jobToItemData(job, index)),
        },
    });
};

export const trackBeginCheckout = (jobs) => {
    const totalValue = jobs.reduce((total, job) => total + job.total.gross, 0);

    dataLayerPush({ ecommerce: null });
    dataLayerPush({
        event: 'begin_checkout',
        ecommerce: {
            currency: 'NOK',
            value: totalValue,
            items: jobs.map((job, index) => jobToItemData(job, index)),
        },
    });
};

export const trackProjectTimeSelect = () => {
    dataLayerPush({
        event: 'project_time_select',
    });
};

export const trackFinishCheckout = (transactionId, jobs) => {
    const totalValue = jobs.reduce((total, job) => total + job.total.gross, 0);

    dataLayerPush({ ecommerce: null });
    dataLayerPush({
        event: 'purchase',
        ecommerce: {
            transaction_id: transactionId,
            currency: 'NOK',
            value: totalValue,
            items: jobs.map((job, index) => jobToItemData(job, index)),
        },
    });
};

export const trackFinishSolarCheckout = () => {
    dataLayerPush({
        event: 'solar_finish_checkout',
    });
};

export const trackSolarBeginConfiguration = () => {
    dataLayerPush({
        event: 'solar_begin_configuration',
    });
};

export const trackInspectionBeginCheckout = () => {
    dataLayerPush({
        event: 'inspection_begin_checkout',
    });
};

export const trackInspectionTimeSelect = () => {
    dataLayerPush({
        event: 'inspection_time_select',
    });
};

export const trackInspectionFinishCheckout = () => {
    dataLayerPush({
        event: 'inspection_finish_checkout',
    });
};

export const pushNotCustomerUser = (user) => {
    dataLayerPush({
        event: 'not_customer_user',
        roles: user.activeRoles,
    });
};
