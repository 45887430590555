import colorsModule from './colors.module.scss';

import gridStylesModule from './../styles/grid.module.scss';
import React from 'react';

export const colors = colorsModule?.locals || colorsModule;

const gridStyles: any = gridStylesModule?.locals || gridStylesModule;

interface RowProps {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export const Row = ({ children, className, style }: RowProps) => {
    return (
        <div
            style={style}
            className={[className, gridStyles.row].filter((c) => c).join(' ')}
        >
            {children}
        </div>
    );
};

interface ColProps {
    width: number | [number] | [number, number] | [number, number, number];
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export const Col = ({ width, children, className, style }: ColProps) => {
    const cols = (index: number) =>
        Array.isArray(width)
            ? width[index] || width[index - 1] || null
            : [width][index] || null;

    return (
        <div
            style={style}
            className={[
                className,
                gridStyles.col,
                cols(0) && gridStyles[`col-${cols(0)}`],
                cols(1) && gridStyles[`col-${cols(1)}-m`],
                cols(2) && gridStyles[`col-${cols(2)}-s`],
            ]
                .filter((c) => c)
                .join(' ')}
        >
            {children}
        </div>
    );
};
