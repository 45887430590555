import objectToQuery from '/lib/utils/objectToQuery';

import api from './api';

export default {
    index: (params) => api.getSecure(`media?${objectToQuery(params)}`),

    upload: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return api.fetch('media/upload', {
            method: 'POST',
            body: formData,
            headers: api.authHeader(),
        });
    },

    customerUpload: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return api.fetch('media/upload-customer-image', {
            method: 'POST',
            body: formData,
            headers: api.authHeader(),
        });
    },

    documentUpload: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return api.fetch('media/upload-document', {
            method: 'POST',
            body: formData,
            headers: api.authHeader(),
        });
    },

    cmsUpload: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return api.fetch('media/upload-cms-image', {
            method: 'POST',
            body: formData,
            headers: api.authHeader(),
        });
    },

    patch: (id, data) => api.patch(`media/${id}`, data),

    delete: (id) => api.delete(`media/${id}`),
};
