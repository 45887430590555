import ContractorType from './contractorType';
import DepartmentType from './departmentType';
import { PermissionType } from './permissionTypes';
import { CompanyInfo } from './projectType';
import { SalesPartner } from './salesPartnerTypes';

export enum UserRole {
    GLOBAL_ADMIN = 'GLOBAL_ADMIN',
    CASEWORKER = 'CASEWORKER',
    INSPECTOR = 'INSPECTOR',
    CONTRACTOR_ADMIN = 'CONTRACTOR_ADMIN',
    ELECTRICIAN = 'ELECTRICIAN',
    STORE_EMPLOYEE = 'STORE_EMPLOYEE',
    CUSTOMER = 'CUSTOMER',
    CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
    SALES_PARTNER_ADMIN = 'SALES_PARTNER_ADMIN',
    SALES_PARTNER_DEPARTMENT_ADMIN = 'SALES_PARTNER_DEPARTMENT_ADMIN',
    SALES_PARTNER_SELLER = 'SALES_PARTNER_SELLER',
}

export enum RoleableContext {
    SALES_PARTNER = 'SALES_PARTNER',
    SALES_PARTNER_DEPARTMENT = 'SALES_PARTNER_DEPARTMENT',
}

export interface ContextableRole {
    role: UserRole;
    context: RoleableContext;
    contextId: number;
}

export interface Author {
    uuid: string;
    name: string;
    phoneNumber: string;
    email: string;
}

export interface UserSettings {
    caseworkerShouldBeAssignedToProjects?: boolean;
    schedulingAssignmentType?: string[];
    workersAmount?: number;
    meta: any;
}

export const roleNames = {
    [UserRole.GLOBAL_ADMIN]: 'Administrator',
    [UserRole.CASEWORKER]: 'Saksbehandler',
    [UserRole.INSPECTOR]: 'Inspektør',
    [UserRole.CONTRACTOR_ADMIN]: 'Partner',
    [UserRole.ELECTRICIAN]: 'Montør',
    [UserRole.STORE_EMPLOYEE]: 'Proff i butikk',
    [UserRole.CUSTOMER]: 'Kunde',
    [UserRole.CUSTOMER_SERVICE]: 'Kundeservice',
    [UserRole.SALES_PARTNER_ADMIN]: 'Partneradmin',
    [UserRole.SALES_PARTNER_DEPARTMENT_ADMIN]: 'Avdelingsadmin',
    [UserRole.SALES_PARTNER_SELLER]: 'Selger',
};

export interface SimpleUserType {
    id: number;
    uuid: string;
    name: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email?: string;
    roles: UserRole[];
    activeRoles: UserRole[];
    availableRoles: UserRole[];
    address?: string;
    postCode?: string;
    _type: 'simple';
    company?: CompanyInfo;
    companies?: CompanyInfo[];
}

export interface CustomerSearchResultType {
    id: number;
    uuid: string;
    address: string;
    postCode?: string;
    email: string;
    name: string;
    phoneNumber: string;
    type: 'private' | 'company';
    company?: CompanyInfo;
    companies?: CompanyInfo[];
}

export interface UserType extends Omit<SimpleUserType, 'roles' | '_type'> {
    permissions: PermissionType[];
    contractor?: ContractorType;
    caseworkerDepartments: DepartmentType[];
    storeEmployeeDepartment?: DepartmentType;
    flags: {
        alreadyAnsweredHearingAboutUs: boolean;
    };
    cart: any;
    remark?: {
        description: string;
        createdBy: SimpleUserType;
        createdAt: string;
    };
    projectCount?: number;
    _type: 'regular';
    settings?: UserSettings;
    salesPartner?: SalesPartner;
}

export type AnyUserType = SimpleUserType | UserType;

export const isSimpleUserType = (user: AnyUserType): user is SimpleUserType => {
    return !('permissions' in user);
};
