// What did you expect to find here?

import compose from 'ramda/src/compose';
import not from 'ramda/src/not';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import filter from 'ramda/src/filter';
import curry from 'ramda/src/curry';

import {array} from './converters'

type ObjectType = {
    [key: string]: any;
};

export const stripEmptyProps = (obj: ObjectType) => filter(compose(not, isEmpty), obj);

export const stripNilProps = (obj: ObjectType) => filter(compose(not, isNil), obj);

export const stripUndefinedProps = (obj: ObjectType) =>
    filter(
        compose(
            not,
            curry((item) => item === undefined)
        ),
        obj
    );

export const stripProps = (props: any[] | string, obj: ObjectType) => {
    return array(props).reduce((obj, key) => {
        const { [key]: unused, ...rest } = obj;
        return rest;
    }, obj);
};