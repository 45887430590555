export default function isObject(v: any) {
    return (
        v &&
        v.constructor !== Array &&
        v === Object(v) &&
        typeof v !== 'function' &&
        v instanceof Promise === false &&
        v instanceof Date === false
    );
}
