import { useMemo } from 'react';
import dynamic from 'next/dynamic';

import config from '../config';
import { AdminBarProps } from './AdminBar';

export default function AdminBarImportexr(props: AdminBarProps) {
    const { canSeeAdminBar } = config.useUser();

    const _ = (_: any) => null;

    const devEnvironment =
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' ||
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging';

    const loadable = devEnvironment || canSeeAdminBar;

    const { isCmsPage } = props;

    return useMemo(() => {
        const AdminBar = !loadable
            ? _
            : dynamic(() => import('./AdminBar'), { ssr: false });
        return <AdminBar {...props} />;
    }, [loadable, isCmsPage]);
}
