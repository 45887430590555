import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { colors, effects } from '/css';

interface Props extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    visible: boolean;
    hideWithStyle?: boolean;
}

const DropdownContent = ({
    children,
    visible,
    hideWithStyle,
    ...props
}: Props) => {
    if (!hideWithStyle && !visible) return null;

    return (
        <Component visible={visible} {...props}>
            {children}
        </Component>
    );
};

export const DropdownItem = styled.div`
    background-color: white;
    padding: 6px 16px 7px;
    cursor: pointer;
    font-size: 17px;
    > div {
        margin-left: 0;
    }
    &:hover {
        @media (pointer: fine) {
            background: ${colors.blueGrey};
            color: ${colors.purpleLight};
        }
    }
`;

const Component = styled.div<{ visible: boolean }>`
    position: absolute;
    z-index: 9999;
    top: calc(100% + 8px);
    right: 0;
    border-radius: 12px;
    overflow: hidden;
    ${effects.shadow};
    min-width: 200px;

    display: ${(p) => (p.visible ? 'grid' : 'none')};
    grid-template-columns: 1fr;
`;

export default DropdownContent;
