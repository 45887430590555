import api from '/api';
import Menu, { MenuItem } from '/front/types/frontMenuType';
import { Error } from '/types/apiResponseTypes';
import camelcaseKeys from 'camelcase-keys';

interface SpotonMenu extends Omit<Menu, '_id' | '_key'> {
    id: Menu['_id'];
    key: Menu['_key'];
}

const stripDomain = (url: string) => url.replace(/^.*\/\/[^\/]+/, '');

const toFrontMenu = (menu: SpotonMenu) => ({
    _id: menu.id,
    _key: menu.key,
    title: menu.title,
    items: menu.items.map((item: MenuItem) =>
        camelcaseKeys({
            ...item,
            url: item.type === 'page' ? stripDomain(item.url) : item.url,
        })
    ),
});

const menuApi = {
    get: async (): Promise<Menu[] | Error> => {
        const resp = await api.get(`cms/navigation`);

        if ('error' in resp) return { error: resp.error };

        return resp.data.map(toFrontMenu);
    },

    patch: async (menuId: string, menu: Menu): Promise<Menu | Error> => {
        const resp = await api.patch(`cms/navigation/${menuId}`, {
            id: menu._id,
            key: menu._key,
            title: menu.title,
            items: menu.items.map((i) => ({
                type: i.type,
                label: i.label,
                meta: i.meta,
                ...(i.type === 'url' ? { url: i.url } : {}),
                ...(i.pageId ? { page_id: i.pageId } : {}),
            })),
        });

        if ('error' in resp) return { error: resp.error };

        return toFrontMenu(resp.data);
    },
};

export default menuApi;
