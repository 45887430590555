import { css } from 'styled-components';

const effects = {
    shadow: 'box-shadow: 0 6px 24px rgba(0,0,0,.06), 0 2px 6px rgba(31,26,34,.06);',
    shadowHover:
        'box-shadow: 0 6px 24px rgba(0,0,0,.12), 0 2px 6px rgba(31,26,34,.06);',
    shine: (opacity = 0.6, speed = 1.5) => `
        &:after {
            content: '';
            position:absolute;
            top:0;
            left:0;
            width: 50%;
            bottom:0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,${opacity}) 50%, rgba(255,255,255,0) 100%);
            display: block;
            transform: translateX(-100%);
            animation: shine ${speed}s ease-in-out 0s infinite;
            z-index: 10;
        }
        @keyframes shine {
            0% { transform: translateX(-100%); }
            100% { transform: translateX(200%);}
        }
    `,
    shadowReset: css`
        box-shadow: none;
    `,
};

export default effects;
