import Modal from '/components/ui/Modal';
import Heading from '/components/ui/typography/Heading';
import Button from '/components/ui/buttons/Button';
import { JobTemplate } from '/types/jobTemplateTypes';
import Image from 'next/image';
import Link from 'next/link';
import PostCodeCheckerInput from './PostCodeCheckerInput';
import usePostCodeState from '/hooks/usePostCodeState';
import { useState } from 'react';
import { useOrderState } from '/store/orderStore';
import useCartReservation from '/hooks/useCartReservation';
import { useRouter } from 'next/router';

interface Props {
    closeRequestCallback?: () => void;
    jobTypeContext?: JobTemplate['typeKey'];
    onContinue?: () => void;
    cartContext?: boolean;
    withoutButtons?: boolean;
}

const PostCodeCheckerModal = ({
    closeRequestCallback,
    jobTypeContext,
    cartContext,
    withoutButtons,
    onContinue,
}: Props) => {
    const [postCodeInputValue, setPostCodeInputValue] = useState<string>('');

    const cart = useOrderState((state) => state.cart);

    const setOrderInspectionDateTime = useOrderState(
        (state) => state.setOrderInspectionDateTime
    );

    const { clearCartReservation } = useCartReservation();

    const router = useRouter();

    const {
        postCodeStateHydrated,
        postCode,
        postCodeSupportedJobTypes,
        resetPostCodeState,
        cartHasJobsUnsupportedInPostCodeLocation,
    } = usePostCodeState();

    const onClose = async () => {
        if (postCodeInputValue === '') {
            resetPostCodeState();
            setOrderInspectionDateTime('');
            if (cart?.reservation) await clearCartReservation();
            if (router.route === '/order/checkout') {
                router.replace('/bestilling/handlekurv');
            }
        }
        if (typeof closeRequestCallback === 'function') {
            closeRequestCallback();
        }
    };

    const hasSupportedJobTypes = postCodeSupportedJobTypes.length !== 0;

    const jobFromContextSupported =
        jobTypeContext && postCodeSupportedJobTypes.includes(jobTypeContext);

    const renderSelectTimeButton =
        postCode &&
        hasSupportedJobTypes &&
        cartContext &&
        !cartHasJobsUnsupportedInPostCodeLocation;

    const renderEditCartButton =
        cartContext &&
        postCode &&
        hasSupportedJobTypes &&
        cartHasJobsUnsupportedInPostCodeLocation;

    const renderAllJobsButton =
        !cartContext &&
        !jobTypeContext &&
        postCode &&
        hasSupportedJobTypes &&
        !withoutButtons;

    const renderNewsletterButton =
        (postCode && jobTypeContext && !jobFromContextSupported) ||
        (postCode && !hasSupportedJobTypes && !withoutButtons);

    const renderAllJobsLink =
        postCode &&
        jobTypeContext &&
        !jobFromContextSupported &&
        !withoutButtons;

    const renderContinueButton =
        onContinue && postCode && jobTypeContext && jobFromContextSupported;

    const closeButtonText = renderContinueButton ? 'Avbryt' : 'Lukk';

    if (!postCodeStateHydrated) return <></>;

    return (
        <Modal
            closeRequestCallback={onClose}
            className="p-32"
            usePortal={false}
            closeButton={false}
            width="355px"
            style={{ zIndex: '9999' }}
        >
            <Image
                src="/images/illustrations/how-it-works-electrician.svg"
                width={100}
                height={70}
                alt="electrician"
                title="electrician"
                className="mb-16-all block centered"
            />
            <Heading
                size="small"
                centered
                style={{ fontSize: '24px', lineHeight: '28px' }}
                className="mb-16-all"
            >
                {!jobTypeContext
                    ? 'Sjekk om vi er tilgjengelig der du bor'
                    : 'Sjekk om vi er leverer denne jobben der du bor'}
            </Heading>

            <PostCodeCheckerInput
                jobTypeContext={jobTypeContext}
                cartContext={cartContext}
                onInputChange={setPostCodeInputValue}
            />

            {renderAllJobsButton && (
                <Button
                    className="mt-16-all"
                    size="medium"
                    fullWidth
                    link="/nettbutikk/alle"
                >
                    Se våre jobber
                </Button>
            )}
            {renderSelectTimeButton && (
                <Button
                    className="mt-16-all"
                    size="medium"
                    fullWidth
                    link="/bestilling/velgtidspunkt"
                >
                    Velg tidspunkt
                </Button>
            )}
            {renderEditCartButton && (
                <Button
                    className="mt-16-all"
                    size="medium"
                    fullWidth
                    onClick={onClose}
                >
                    Se over jobbene
                </Button>
            )}
            {renderNewsletterButton && (
                <Button
                    className="mt-16-all"
                    size="medium"
                    fullWidth
                    link="/nyhetsbrev"
                >
                    Skriv deg på venteliste
                </Button>
            )}
            {renderContinueButton && (
                <Button
                    className="mt-16-all"
                    size="medium"
                    fullWidth
                    onClick={() => {
                        onContinue();
                        onClose();
                    }}
                >
                    Fortsett
                </Button>
            )}
            <Button
                className="mt-12-all"
                size="medium"
                fullWidth
                variant="tertiary"
                onClick={onClose}
            >
                {closeButtonText}
            </Button>
            {renderAllJobsLink && (
                <Link
                    href="/nettbutikk/alle"
                    className="mt-12-all flex justify-center"
                >
                    Se tilgjengelige jobber
                </Link>
            )}
        </Modal>
    );
};

export default PostCodeCheckerModal;
