import { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import { colors, effects } from '/css';
import Icon from '/components/ui/Icon';
import validators from '/lib/utils/validators';
import Description from './typography/Description';

/*
|--------------------------------------------------------------------------
|  Validation in parent component
|--------------------------------------------------------------------------
| 1. give <Input /> a ref "INPUT_NAME_input"
| 2. Use code below to validate all input components in parent:

    constructor() {
        this._refs = {
            phone_input: inputRef,
        }
    }

    validateInputs() {
        let allFieldsValid = true;

        Object.keys(this._refs).map(refName => {
            if(~refName.indexOf('_input') && this._refs[refName].current && typeof this._refs[refName].current.validate === 'function') {
                if(!this._refs[refName].current.validate()) {
                    allFieldsValid = false;
                }
            }
            return null;
        });

        return allFieldsValid
    }

*/

class LegacyInput extends Component {
    constructor(props) {
        super(props);

        this.rules = this.props.rules || {};

        this.state = {
            value: this.checkValue(
                this.props.value === undefined ? '' : this.props.value
            ),
            validationError: null,
        };

        this.id =
            this.props.id ||
            'input-' + (this.props.name || nanoid(9)).replace(/\s/g, '-');
    }

    componentDidMount() {
        if (this.props.autoFocus) this.focus();
    }

    focus() {
        const input = document.getElementById(this.id);
        if (input) input.focus();
    }

    checkValue(v) {
        const rules = this.rules;
        let value = String(v) || '';

        if (rules.integer) value = value.replace(/[\D]/g, '');

        if (rules.float)
            value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); //value.replace(/[^\d.-]/g, '').replace(/(?!^)-/g, '').replace('.', '%FD%').replace(/\./g, '').replace('%FD%', '.');

        //if(value && (rules.integer || rules.float) && rules.min && Number(value) < rules.min) value = rules.min;
        //if(!value && rules.min && (rules.integer || rules.float)) value = rules.min;

        if (
            value &&
            (rules.integer || rules.float) &&
            rules.max &&
            Number(value) > rules.max
        )
            value = rules.max;

        if (value && rules.maxLength && value.length > rules.maxLength)
            value = value.substring(0, rules.maxLength);

        //if(rules.regexp && rules.regexp instanceof RegExp && !rules.regexp.test(value) && this.state) {
        //return value ? this.state.value : ''
        //}

        if (rules.phone) {
            value = value.replace(/[^\d\+]/g, '');
        }

        return value;
    }

    validate() {
        const { value } = this.state;
        const rules = this.rules;

        this.setState({ validationError: null });

        if (rules.required && value === '') {
            this.setState({ validationError: 'Må fylles ut' });
            return false;
        }

        if (rules.email && !validators.email(value)) {
            this.setState({ validationError: 'Gi riktig e-post' });
            return false;
        }

        if (rules.phone && !validators.phone(value)) {
            this.setState({ validationError: 'Telefonnummer ikke gyldig' });
            return false;
        }

        if (rules.regexp && rules.regexp instanceof RegExp) {
            const r1 = rules.regexp.test(value);
            // eslint-disable-next-line
            const r2 = rules.regexp.test(value); // ... apparently this is required. I know, right?
            if (!r1) {
                this.setState({ validationError: 'Feil verdi' });
                return false;
            }
        }

        if (
            (rules.integer || rules.float) &&
            rules.min &&
            Number(value) < rules.min
        ) {
            this.setState({ validationError: 'Minste verdi er ' + rules.min });
            return false;
        }

        if (
            !rules.integer &&
            !rules.float &&
            rules.min &&
            value.length < rules.min
        ) {
            this.setState({ validationError: 'Minst ' + rules.min + ' tegn' });
            return false;
        }

        return true;
    }

    onChange(e) {
        let value = this.checkValue(e.target.value);
        this.setState({ value });

        const rules = this.props.rules || {};

        if (value && (rules.integer || rules.float)) value = Number(value);

        this.props.onChange(value, e);
    }

    clear() {
        this.setState({ value: '' });
        this.props.onChange('');
        if (this.props.onClear) this.props.onClear();
        const input = document.getElementById(this.id);
        if (input) input.focus();
    }

    remove() {
        if (this.props.onRemove) this.props.onRemove();
    }

    componentDidUpdate(prevProps) {
        if (String(prevProps.value) !== String(this.props.value)) {
            const value = this.checkValue(this.props.value);
            this.setState({ value });
        }
    }

    onBlur(e) {
        if (this.props.onBlur) this.props.onBlur(e);

        if (this.state.value || this.rules.required) {
            this.validate();
        } else {
            this.setState({ validationError: null });
        }
    }

    render() {
        const {
            label,
            name,
            className,
            placeholder,
            suffix,
            prefix,
            clearable,
            alwaysClearable,
            removable,
            isValid,
            autocomplete,
            readOnly,
            multiline,
            isInvalid,
            selectOnFocus,
            icon,
            iconRightAlign,
            iconOnClick,
            style,
            type,
            onKeyPress,
            small,
            showValidationMessage = true,
            apiValidationErrors,
            description,
        } = this.props;
        const { value, validationError } = this.state;

        const invalid = isInvalid || validationError;
        const valid = isValid;

        const InputTag = multiline ? 'textarea' : 'input';

        return (
            <Wrapper
                className={[
                    'input',
                    className,
                    invalid && 'invalid',
                    small && 'small',
                    value && `has-value`,
                ]
                    .filter((c) => c)
                    .join(' ')}
                iconRightAlign={iconRightAlign}
                style={style}
            >
                {label && (
                    <label htmlFor={this.id}>
                        {label}
                        {this.rules.optional && <span>Valgfritt</span>}
                    </label>
                )}
                {description && (
                    <Description className="input-description">
                        {description}
                    </Description>
                )}
                <div>
                    {icon && <Icon icon={icon} />}
                    {icon && iconOnClick && (
                        <div
                            className="icon-click-area"
                            onClick={iconOnClick}
                        />
                    )}
                    <InputTag
                        id={this.id}
                        placeholder={placeholder}
                        autoComplete={autocomplete}
                        value={value}
                        name={name}
                        type={type || 'text'}
                        onChange={this.onChange.bind(this)}
                        onFocus={(e) =>
                            selectOnFocus ? e.target.select() : null
                        }
                        onBlur={this.onBlur.bind(this)}
                        className={[
                            suffix ? 'has-suffix' : '',
                            prefix ? 'has-prefix' : '',
                            icon ? 'has-icon' : '',
                            iconRightAlign ? 'icon-right' : '',
                            valid ? 'valid' : '',
                        ]
                            .filter((v) => v)
                            .join(' ')}
                        onKeyPress={onKeyPress}
                        readOnly={readOnly}
                    />
                    <div className="suffix">
                        {!invalid && suffix}
                        {invalid && <Icon icon="error" />}
                        {isValid && <Icon icon="check" fill={colors.green} />}
                        {clearable &&
                            !readOnly &&
                            (value || alwaysClearable) && (
                                <Icon
                                    icon="empty-field"
                                    className="empty-field"
                                    onClick={this.clear.bind(this)}
                                />
                            )}
                        {removable && !readOnly && (
                            <Icon
                                icon="empty-field"
                                className="empty-field"
                                onClick={this.remove.bind(this)}
                            />
                        )}
                    </div>
                    {prefix && (
                        <div
                            className={`prefix ${readOnly ? 'read-only' : ''}`}
                        >
                            {prefix}
                        </div>
                    )}
                    {showValidationMessage && invalid && (
                        <span className="error input-error-message">
                            {invalid}
                        </span>
                    )}
                </div>
                {!!apiValidationErrors?.length && (
                    <ul className="api-validation-errors">
                        {apiValidationErrors.map((err, i) => (
                            <li key={i}>{err}</li>
                        ))}
                    </ul>
                )}
            </Wrapper>
        );
    }
}

LegacyInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    suffix: PropTypes.string,
    prefix: PropTypes.string,
    description: PropTypes.string,
    isInvalid: PropTypes.bool,
    showValidationMessage: PropTypes.bool,
    isvalid: PropTypes.bool,
    selectOnFocus: PropTypes.bool,
    rules: PropTypes.object, // { integer: true, float: true, required: true, min: 0, max: 10, email: true, regexp: /.../ }
    value: PropTypes.any.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.string,
};

export default LegacyInput;

const Wrapper = styled.div`
    > label {
        font-size: 17px;
        font-family: 'gellix-semi';
        display: block;
        margin-bottom: 8px;
        span {
            font-size: 15px;
            line-height: 23px;
            color: ${colors.grey};
            font-family: 'gellix-medium';
            padding-left: 7px;
        }
    }
    .input-description {
        position: relative;
        top: -6px;
    }
    > div {
        position: relative;
        > svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            ${(props) => (props.iconRightAlign ? 'right:16px;' : 'left: 16px;')}
        }
        .icon-click-area {
            position: absolute;
            z-index: 99;
            top: 6px;
            width: 44px;
            height: 44px;
            cursor: pointer;
            ${(props) => (props.iconRightAlign ? 'right:6px;' : 'left: 6px;')}
        }
        > .suffix {
            font-size: 17px;
            font-family: 'gellix-semi';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            display: flex;
            .empty-field {
                cursor: pointer;
            }
        }
        > .prefix {
            font-size: 17px;
            font-family: 'gellix-semi';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 16px;
            &.read-only {
                color: ${colors.grey};
            }
        }
        input,
        textarea {
            height: 54px;
            border: 3px solid white;
            background: #fff;
            outline: none;
            border-radius: 12px;
            ${effects.shadow};
            padding: 12px 16px 15px;
            font-size: 17px;
            color: ${colors.balck};
            transition: all 0.2s ease-in-out;
            &.has-icon {
                &:not(.icon-right) {
                    padding-left: 48px;
                }
                &.icon-right {
                    padding-right: 48px;
                }
            }
            &.has-suffix {
                padding-right: 70px;
            }
            &.has-prefix {
                padding-left: 50px;
            }
            &::placeholder {
                color: ${colors.grey};
                opacity: 1;
            }
            &:focus {
                border: 3px solid #7e69fd;
            }
            &:hover {
                ${effects.shadowHover};
            }
            &.valid {
                border: 3px solid ${colors.green};
            }
            &:read-only {
                opacity: 1;
                color: ${colors.grey};
                &:hover {
                    ${effects.shadow};
                }
                &:focus {
                    border: 3px solid #fff;
                }
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        input[type='number'] {
            -moz-appearance: textfield;
        }
        textarea {
            min-height: 168px;
            line-height: 1.3;
        }
    }

    &.invalid {
        > label {
            //color: ${colors.orange};
        }
        > div {
            input,
            textarea {
                border: 3px solid ${colors.orange}!important;
            }
            .error {
                position: absolute;
                top: calc(100% + 4px);
                left: 0;
                width: 100%;
                color: ${colors.orange};
            }
        }
    }

    &.small {
        > div {
            input {
                font-size: 17px !important;
                line-height: 1.3;
                height: 36px;
                padding: 5px 12px 5px;
            }
            textarea {
                font-size: 17px !important;
                line-height: 1.3;
                min-height: 100px;
                height: 100px;
                padding: 5px 12px 5px;
            }
        }
    }

    ul.api-validation-errors {
        all: unset;
        font-size: 0.8em;
        color: ${colors.orange};

        li {
            all: unset;
            display: block;
        }
    }
`;
