import { useState } from 'react';
import useOnClickOutside from './useOnClickOutside';

const useDropdown = (closeOnBackgroundClick = true) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useOnClickOutside(() => setIsOpen(false));

    return {
        dropdownRef,
        dropdownIsOpen: isOpen,
        openDropdown: () => setIsOpen(true),
        closeDropdown: () => setIsOpen(false),
        toggleDropdown: () => setIsOpen(!isOpen),
    };
};

export default useDropdown;
