import { useEffect, useState } from 'react';
import { StoreApi, UseBoundStore } from 'zustand';

export const useInitialState = <StoreType, StateType>(
    stateHook: UseBoundStore<StoreApi<StoreType>>,
    initialState: StateType,
    selectorFunction: (state: StoreType) => any
) => {
    const state = stateHook(selectorFunction);

    const [hydrated, setHydrated] = useState(false);

    useEffect(() => setHydrated(true), []);

    return hydrated
        ? state
        : selectorFunction({
              ...stateHook.getState(),
              ...initialState,
          });
};

export const useHydration = () => {
    const [hydrated, setHydrated] = useState(false);

    useEffect(() => setHydrated(true), []);

    return hydrated;
};
