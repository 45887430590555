import { Children, isValidElement, ReactNode } from 'react';

export default function areValidChildren(children: ReactNode): boolean {
    return Children.toArray(children).some((child) => {
        if (isValidElement(child)) {
            if (child.props.children) {
                return areValidChildren(child.props.children);
            }
            return true;
        }
        return !!child;
    });
}
