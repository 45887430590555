const spreadPropsClassName = (
    props: Record<string, unknown> = {},
    className: string = ''
) => {
    const { className: propClassName, ...remainingProps } = props;
    return {
        className: [className, propClassName].join(' '),
        ...remainingProps,
    };
};

export default spreadPropsClassName;
