import { useOrderState } from '/store/orderStore';
import { useHydration } from '/store/store.helpers';
import {
    PostCodeHookReturn as OriginalPostCodeState,
    PostCodeStateHook,
    PostCodeStateValues,
} from './usePostCodeStateHook.types';

export type PostCodeState = OriginalPostCodeState;

const usePostCodeState: PostCodeStateHook = (stateOverride) => {
    const postCodeStateHydrated = useHydration();

    const cart = useOrderState((state) => state.cart);

    const postCode = useOrderState((state) => state.postCode);
    const setPostCode = useOrderState((state) => state.setOrderPostCode);

    const postCodePlace = useOrderState((state) => state.postCodePlace);
    const setPostCodePlace = useOrderState((state) => state.setPostCodePlace);

    const postCodeValid = useOrderState((state) => state.postCodeValid);
    const setPostCodeValid = useOrderState(
        (state) => state.setOrderPostCodeValidity
    );

    const postCodeSupportedJobTypes = useOrderState(
        (state) => state.postCodeSupportedJobTypes
    );
    const setPostCodeSupportedJobTypes = useOrderState(
        (state) => state.setOrderPostCodeSupportedJobTypes
    );

    const postCodeNotificationRequest = useOrderState(
        (state) => state.postCodeNotificationRequested
    );
    const setPostCodeNotificationRequest = useOrderState(
        (state) => state.setOrderPostCodeNotificationRequest
    );

    const cartHasJobsUnsupportedInPostCodeLocation = !!(
        cart &&
        !!cart.lineItems.length &&
        cart.lineItems.some(
            (item) =>
                item.type === 'JOB' &&
                item.data.typeKey &&
                !postCodeSupportedJobTypes.includes(item.data.typeKey)
        )
    );

    const resetPostCodeState = async () => {
        setPostCode('');
        setPostCodePlace('');
        setPostCodeValid(false);
        setPostCodeNotificationRequest(false);
        setPostCodeSupportedJobTypes([]);
    };

    const setPostCodeState = (state: Partial<PostCodeStateValues>) => {
        if (state.postCode) setPostCode(state.postCode);
        if (state.postCodePlace) setPostCodePlace(state.postCodePlace);
        if (state.postCodeValid !== undefined)
            setPostCodeValid(!!state.postCodeValid);
        if (state.postCodeSupportedJobTypes)
            setPostCodeSupportedJobTypes(state.postCodeSupportedJobTypes);
        if (state.postCodeNotificationRequest !== undefined)
            setPostCodeNotificationRequest(state.postCodeNotificationRequest);
    };

    return (
        stateOverride || {
            postCodeStateHydrated,
            resetPostCodeState,
            postCode,
            setPostCode,
            postCodePlace,
            setPostCodePlace,
            postCodeValid,
            setPostCodeValid,
            postCodeSupportedJobTypes,
            setPostCodeSupportedJobTypes,
            postCodeNotificationRequest,
            setPostCodeNotificationRequest,
            setPostCodeState,
            cartHasJobsUnsupportedInPostCodeLocation,
        }
    );
};

export default usePostCodeState;
