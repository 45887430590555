type ParsedQueryParams = {
    [key: string]: string | string[];
};

export default function parseQueryParams(
    queryString: string
): ParsedQueryParams {
    const queryParams = new URLSearchParams(queryString);
    const params: ParsedQueryParams = {};

    queryParams.forEach((value, key) => {
        if (key.endsWith('[]')) {
            const arrayKey = key.slice(0, -2);
            if (!params[arrayKey]) {
                params[arrayKey] = [];
            }
            (params[arrayKey] as string[]).push(value);
        } else {
            params[key] = value;
        }
    });

    return params;
}
