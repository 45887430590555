import moment from '/lib/utils/moment';
import { useEffect } from 'react';
import pipe from 'ramda/src/pipe';
import toUpper from 'ramda/src/toUpper';
import split from 'ramda/src/split';
import map from 'ramda/src/map';
import head from 'ramda/src/head';
import join from 'ramda/src/join';

export { default as userIs } from './utils/userIs';
export { default as areValidChildren } from './utils/areValidChildren';
export { default as isTouchDevice } from './utils/isTouchDevice';
export { default as insertAtIndex } from './utils/insertAtIndex';
export { default as matchText } from './utils/matchText';
export { default as formatDateRange } from './utils/formatDateRange';
export { default as toInt } from './utils/toInt';
export { default as camelcaseKeys } from './utils/camelcaseKeys';
import isObject from './utils/isObject';
export { isObject };
export { default as lineItemToJob } from './utils/lineItemToJob';
export { default as parseQueryParams } from './utils/parseQueryParams';
export { default as imgixUrl } from './utils/imgixUrl';
export { default as box } from './utils/box';
export { default as isRetina } from './utils/isRetina';
export { default as bytesToSize } from './utils/bytesToSize';
export { default as goTo } from './utils/goTo';
export { default as objectToQuery } from './utils/objectToQuery';
export { default as isServer } from './utils/isServer';
export { default as cookie } from './utils/cookie';
export { default as isSupported } from './utils/isSupported';
export { default as decorateText } from './utils/decorateText';
export {
    formatDiscountPrice,
    formatDiscountGrossPrice,
} from './utils/formatPrice';
export {
    stripEmptyProps,
    stripNilProps,
    stripUndefinedProps,
    stripProps,
} from './utils/strippers';
export { areSameArrays, areSameObjects } from './utils/areSame';
export { default as scrollToId } from './utils/scrollToId';
export { default as formatDateTime } from './utils/formatDateTime';
export { default as formatPhoneNumber } from './utils/formatPhoneNumber';
export { default as unique } from './utils/unique';
export { default as formatBigNumber } from './utils/formatBigNumber';
export { default as stripHtmlTags } from './utils/stripHtmlTags';

export const scrollWindowTo = (
    destination,
    duration = 200,
    easing = 'easeInOutCubic',
    callback
) => {
    const easings = {
        linear: (t) => t,
        easeInOutCubic: (t) =>
            t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    };

    if (!destination && destination !== 0) return;

    const start = window.pageYOffset;
    const startTime =
        'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );
    const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset =
        typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
            ? documentHeight - windowHeight
            : destinationOffset
    );

    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
    }

    function scroll() {
        const now =
            'now' in window.performance
                ? performance.now()
                : new Date().getTime();
        const time = Math.min(1, (now - startTime) / duration);
        const timeFunction = easings[easing](time);
        window.scroll(
            0,
            Math.ceil(
                timeFunction * (destinationOffsetToScroll - start) + start
            )
        );

        if (
            Math.ceil(window.pageYOffset) ===
            Math.ceil(destinationOffsetToScroll)
        ) {
            if (callback) {
                callback();
            }
            return;
        }

        requestAnimationFrame(scroll);
    }

    scroll();
};

export const getElementOffsetY = (element) => {
    if (typeof window === 'undefined' || !element) return 0;

    const bodyRect = document.body.getBoundingClientRect(),
        elemRect = element.getBoundingClientRect();

    return elemRect.top - bodyRect.top;
};

export const hex2rgb = (hex) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
    }
    throw new Error('Invalid HEX value provided for BarLoader prop "color" ');
};

export const capitalize = (str) =>
    String(str)
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.slice(1))
        .join(' ');

export const capitalizeFirst = (str) => {
    if (!str) return '';
    return String(str)[0].toUpperCase() + String(str).slice(1);
};

export const truncate = (fullStr, strLen) => {
    if (typeof fullStr !== 'string') return '';

    if (fullStr.length <= strLen) return fullStr;

    const separator = '...';
    const sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

    return (
        fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars)
    );
};

export const formatDateTimeWithYear = (dateString) => {
    if (!dateString) return '';

    const date = moment(dateString);
    return `${date.format('DD.MM.YY')} • kl. ${date.format('HH:mm')}`;
};

export const formatDate = (dateString, format = 'DD. MMMM YYYY') => {
    if (!dateString) return '';

    const date = moment(dateString);

    return date.isValid() ? `${date.format(format)}` : '';
};

export const formatThousands = (val) => {
    if (isNaN(Math.ceil(val))) return '';
    return (Math.round((val / 1000) * 10) / 10 + "'").replace('.', ',');
};

export const formatPrice = (val) => {
    if (isNaN(Math.ceil(val))) return '';
    return 'kr ' + String(Math.ceil(val)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatNumber = (val) => {
    if (!val) return '';
    if (isNaN(Math.ceil(val))) return '';
    return String(Math.ceil(val)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatTaxPrice = (val) => 'Inkl. mva';

export const snakeToCamel = (str) => {
    return str.replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', '')
    );
};

export const camelToSnake = (str) => {
    return str
        .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
        .replace(/([a-z0-9])([A-Z])/, '$1_$2')
        .toLowerCase();
};

export const camelToKebab = (str) => {
    return str
        .replace(/(.)([A-Z][a-z]+)/, '$1-$2')
        .replace(/([a-z0-9])([A-Z])/, '$1-$2')
        .toLowerCase();
};

export const kebabCase = (str) => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
};

export const useOnClickOutside = (ref, callback) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) callback(e);
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);
};

export const stringContainsOneOf = (string, array) => {
    return array.reduce((acc, item) => {
        return ~string.indexOf(item) ? true : acc;
    }, false);
};

export const stringStartsWithOneOf = (string, array) => {
    return array.reduce((acc, item) => {
        return String(string).indexOf(item) === 0 ? true : acc;
    }, false);
};

export const pathNameIs = (pathName) => {
    return {
        backend: stringStartsWithOneOf(pathName || '', [
            '/dashboard',
            '/definition',
            '/project',
            '/inspection',
            '/claim',
            '/offer',
            '/analytics',
            '/settings',
            '/calendar',
            '/electrician',
            '/standard-availability',
            '/contractor',
            '/product',
            '/seller-dashboard',
            '/concerns',
            '/customer',
            '/draft-version',
            '/resource',
            '/sales-partner',
            '/onboarding',
        ]),
        checkout: stringStartsWithOneOf(pathName || '', ['/order']),
        auth: stringStartsWithOneOf(pathName || '', ['/auth']),
        home: (pathName || '') === '/',
        onboarding: stringStartsWithOneOf(pathName || '', ['/onboarding']),
        configurator: stringStartsWithOneOf(pathName || '', ['/order/job']),
        videoMeeting:
            stringStartsWithOneOf(pathName || '', ['/project']) &&
            stringContainsOneOf(pathName || '', ['/inspection/video']),
    };
};

export const momentFromWeekDay = (day) =>
    moment().locale('en').isoWeekday(capitalize(day)).locale('nb');

export const kebabToCamel = (str) =>
    str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

export const sortByPath = (array = [], path = '', asNumber = false) => {
    const pathParts = path.split('.');
    return [...array].sort((a, b) => {
        let A = a[pathParts[0]];
        if (pathParts.length > 1) A = a[pathParts[0]][pathParts[1]];
        if (pathParts.length > 2)
            A = a[pathParts[0]][pathParts[1]][pathParts[2]];
        A = asNumber ? Number(A) : A.toUpperCase();

        let B = b[pathParts[0]];
        if (pathParts.length > 1) B = b[pathParts[0]][pathParts[1]];
        if (pathParts.length > 2)
            B = b[pathParts[0]][pathParts[1]][pathParts[2]];
        B = asNumber ? Number(B) : B.toUpperCase();

        let comparison;
        if (A > B) {
            comparison = 1;
        } else if (A < B) {
            comparison = -1;
        }
        return comparison;
    });
};

export const roundTo = (number, precision = 0) => {
    const power = Math.pow(10, precision);
    return Math.ceil((number || 0) * power) / power;
};

export const relevantGeneralAvailability = (electrician) => {
    if (!electrician) return null;
    if (electrician.availability) return electrician.availability;
    const gen = electrician.generalAvailability;
    if (gen && (gen.future || gen.current))
        return gen.future ? gen.future.availability : gen.current.availability;
    return null;
};

export const arraysEqual = (a, b) => {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
};

export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};

export const trim2 = (val) => parseInt(val * 100) / 100;

export const trim1 = (val) => parseInt(val * 10) / 10;

export const percentOf = (x, y) => {
    const p = Math.ceil((x / (y || 1)) * 100);
    return isNaN(p) ? 0 : p;
};

export const percentToNum = (per, max) => Math.ceil((max / 100) * per);

export const isInIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const changeArrayItemPosition = (array, currentIndex, newIndex) => {
    let reorderedarray = [...array];
    const x = reorderedarray[currentIndex];
    reorderedarray[currentIndex] = reorderedarray[newIndex];
    reorderedarray[newIndex] = x;
    return reorderedarray;
};

export const isEmail = (email) =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

export const isPhone = (val) => {
    if (String(val).indexOf('+48') === 0)
        return /^(0048|\+48|48)?\d{9}$/.test(val);
    return /^(0047|\+47|47)?\d{8}$/.test(val);
};

export const latinise = (str) => {
    const translate = {
        ä: 'a',
        å: 'a',
        æ: 'ae',
        ö: 'o',
        ø: 'o',
        ü: 'u',
        Ä: 'A',
        Å: 'A',
        Æ: 'AE',
        Ö: 'O',
        Ø: 'O',
        Ü: 'U',
    };
    return str.replace(/[öøäåæüÖØÄÅÆÜ]/g, (match) => translate[match] || '');
};

export const inputFocusBlur = (id, scroll = true) => {
    const input = document.getElementById(id);
    if (input) {
        input.focus({ preventScroll: true });
        input.blur();
        if (scroll)
            input.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};

export const updateObjectPath = (object, path, value) => {
    let keys = path.split('.').filter((k) => k);
    let clone = JSON.parse(JSON.stringify(object));
    let temp = clone;

    for (let index = 0; index < keys.length; index++) {
        const key = keys[index];

        if (index === keys.length - 1) {
            clone[key] = value;
        } else if (!isObject(clone[key])) {
            clone[key] = {};
        }

        clone = clone[key];
    }

    return temp;
};

export const acronym = pipe(toUpper, split(' '), map(head), join(''));

export const windowHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
