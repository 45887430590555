// @ts-nocheck
import Link from 'next/link';

const decorateText = (text) => {
    if (!text) return '';

    const prepare = (item, regex) => {
        if (typeof item !== 'string') return { matches: false, splits: false };

        const matches = item.match(regex);

        if (!matches) return { matches: false, splits: false };

        const splits = item.split(regex);

        return { matches, splits };
    };

    const parseLinks = (acc, item, i) => {
        const { matches, splits } = prepare(item, /\[[\wæÆØøåÅ\s-#\/]+\]/g);

        if (!matches || !splits) return [...acc, item];

        return [
            ...acc,
            ...splits.reduce((acc, item, j) => {
                const linekShortcode = matches[j];

                if (!linekShortcode) return [...acc, item];

                let href = '';
                let text = '';
                let path = '';
                let target = '_self';

                if (~linekShortcode.indexOf(' /')) {
                    text = linekShortcode.split(' /')[0].replace('[', '');
                    path = '/' + linekShortcode.split(' /')[1].replace(']', '');
                    href = path;
                    if (~path.indexOf('_blank')) {
                        path = path.replace('_blank', '').trim();
                        href = path; // opt out of using href & as - next allows to use only href and does resolving internally
                        target = '_blank';
                    }
                    if (~path.indexOf('/bestilling/jobb')) {
                        //href = '/order/job/[slug]'
                        href = path; // opt out of using href & as - next allows to use only href and does resolving internally
                    }
                }

                return [
                    ...acc,
                    item,
                    <Link
                        prefetch={false}
                        key={'l-' + i + '-' + j}
                        href={href}
                        target={target}
                    >
                        {text}
                    </Link>,
                ];
            }, []),
        ];
    };

    const parseLinebreaks = (acc, item, i) => {
        const { matches, splits } = prepare(item, /(?:<br\s?\/>)|(?:\n)/g);

        if (!matches || !splits) return [...acc, item];

        const lastSplit = splits.pop();

        const addBrs = (acc, item, j) => [
            ...acc,
            item,
            <br key={'b-' + i + '-' + j} />,
        ];

        return [...acc, ...splits.reduce(addBrs, []), lastSplit];
    };

    const parseEmails = (acc, item, i) => {
        const { matches, splits } = prepare(
            item,
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
        );

        if (!matches || !splits) return [...acc, item];

        return [
            ...acc,
            ...splits.reduce((acc, item, j) => {
                if (!matches[j]) return [...acc, item];
                return [
                    ...acc,
                    item,
                    <a key={'e-' + i + '-' + j} href={`mailto:${matches[j]}`}>
                        {matches[j]}
                    </a>,
                ];
            }, []),
        ];
    };

    const parsePhones = (acc, item, i) => {
        const { matches, splits } = prepare(item, /\+47[0-9]{8}/g);

        if (!matches || !splits) return [...acc, item];

        return [
            ...acc,
            ...splits.reduce((acc, item, j) => {
                if (!matches[j]) return [...acc, item];
                return [
                    ...acc,
                    item,
                    <a key={'l-' + i + '-' + j} href={`tel:${matches[j]}`}>
                        {matches[j]}
                    </a>,
                ];
            }, []),
        ];
    };

    const parseUrls = (acc, item, i) => {
        const { matches, splits } = prepare(
            item,
            /http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\(\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+/gi
        );

        if (!matches || !splits) return [...acc, item];

        return [
            ...acc,
            ...splits.reduce((acc, item, j) => {
                if (!matches[j]) return [...acc, item];
                return [
                    ...acc,
                    item,
                    <a
                        key={'u-' + i + '-' + j}
                        target="_blank"
                        rel="noreferrer"
                        href={matches[j]}
                    >
                        {matches[j]}
                    </a>,
                ];
            }, []),
        ];
    };

    return [String(text).trim()]
        .reduce(parseLinks, [])
        .reduce(parseLinebreaks, [])
        .reduce(parseEmails, [])
        .reduce(parsePhones, [])
        .reduce(parseUrls, []);
};

export default decorateText;