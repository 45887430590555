import RichTextType from "/front/types/frontRichTextType";

export const richContentIsEmpty = (content?: RichTextType | null) => {
    if (!content?.length) return true;

    return (
        content &&
        content.length === 1 &&
        content[0]._type === 'block' &&
        content[0].children.length === 1 &&
        content[0].children[0]._type === 'span' &&
        content[0].children[0].text === ''
    );
};

export default richContentIsEmpty