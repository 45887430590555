import Router from 'next/router';

const goTo = (
    url: string,
    asNewTab = false,
    afterPush: () => void | null = () => null
) => {
    if (typeof window === 'undefined' || !window) return;

    if (asNewTab) {
        url = process.env.NEXT_PUBLIC_APP_URL + url;
        // @ts-ignore
        return window.open(url, '_blank').focus();
    }
    // @ts-ignore
    if (window.event && (window.event.metaKey || window.event.ctrlKey)) {
        return window.open(process.env.NEXT_PUBLIC_APP_URL + url, '_blank');
    }
    Router.push(url).then(() => {
        window.scrollTo(0, 0);
        afterPush();
    });
    return null;
};

export default goTo;
