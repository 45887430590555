import api from './api';

export default {
    index: async (roles) =>
        api.getSecure(`user?${roles.map((r) => `roles[]=${r}`).join('&')}`),

    getCaseworkerDepartments: async () =>
        api.getSecure('user/caseworker-departments'),

    updateCaseworkerDepartments: async (departmentIds) =>
        api.patch('user/caseworker-departments', departmentIds),

    setActiveRoles: (roles) => api.post(`user/set-active-roles`, roles),

    guidedTourFinished: (tourName) =>
        api.post(`user/guided-tour-finished`, { tour: tourName }),
};
