import { HTMLAttributes } from 'react';
import { colors } from '/css';

interface Props extends HTMLAttributes<SVGElement> {
    color?: string;
}

const PointerIcon = ({ color = colors.purpleDarker2, ...props }: Props) => {
    return (
        <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.916992 3.9375C0.916992 2.89321 1.33183 1.89169 2.07026 1.15327C2.80868 0.414843 3.8102 0 4.85449 0C5.89878 0 6.9003 0.414843 7.63872 1.15327C8.37715 1.89169 8.79199 2.89321 8.79199 3.9375C8.79199 4.98179 8.37715 5.98331 7.63872 6.72173C6.9003 7.46016 5.89878 7.875 4.85449 7.875C3.8102 7.875 2.80868 7.46016 2.07026 6.72173C1.33183 5.98331 0.916992 4.98179 0.916992 3.9375ZM4.85449 2.1875C5.09512 2.1875 5.29199 1.99063 5.29199 1.75C5.29199 1.50937 5.09512 1.3125 4.85449 1.3125C3.40527 1.3125 2.22949 2.48828 2.22949 3.9375C2.22949 4.17812 2.42637 4.375 2.66699 4.375C2.90762 4.375 3.10449 4.17812 3.10449 3.9375C3.10449 2.97227 3.88926 2.1875 4.85449 2.1875ZM3.97949 13.125V8.6707C4.26387 8.72266 4.55645 8.75 4.85449 8.75C5.15254 8.75 5.44512 8.72266 5.72949 8.6707V13.125C5.72949 13.609 5.33848 14 4.85449 14C4.37051 14 3.97949 13.609 3.97949 13.125Z"
                fill={color}
            />
        </svg>
    );
};

export default PointerIcon;
