import Icon from '/components/ui/Icon';
import Button from '/components/ui/buttons/Button';

interface Props {
    onClick: (e: any) => void;
}

const HelpButton = ({ onClick }: Props) => {
    return (
        <Button
            variant="tertiary"
            size="small"
            style={{
                width: '40px',
                minWidth: '40px',
                height: '36px',
                padding: '0px',
            }}
            onClick={onClick}
            className="help-icon"
        >
            <Icon
                icon="help"
                style={{
                    position: 'relative',
                    width: '20px',
                    height: '20px',
                }}
            />
        </Button>
    );
};

export default HelpButton;
