import styled, { css } from 'styled-components';

export enum ContainerSizes {
    LARGE = 'large',
    MEDIUM = 'medium',
    MEDIUM_SMALL = 'mediumSmall',
    SMALL = 'small',
    SMALLER = 'smaller',
}

export const containerWidthForSize = (size: ContainerSizes) => {
    switch (size) {
        case ContainerSizes.MEDIUM:
            return 1000;
        case ContainerSizes.MEDIUM_SMALL:
            return 800;
        case ContainerSizes.SMALL:
            return 650;
        case ContainerSizes.SMALLER:
            return 550;
        default:
            return 1160;
    }
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    flex?: boolean;
    wrap?: boolean;
    direction?: 'row' | 'column';
    justify?:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'space-between'
        | 'space-around';
    align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
    large?: boolean;
    medium?: boolean;
    mediumSmall?: boolean;
    small?: boolean;
    smallFromTablet?: boolean;
    smaller?: boolean;
    custom?: string;
    size?: ContainerSizes;
}

interface StyledProps extends Omit<Props, 'wrap'> {
    $wrap?: boolean;
}

const Container = ({ wrap, ...props }: Props) => {
    return <ContainerEl $wrap={wrap} {...props} />;
};

export default Container;

const ContainerEl = styled.div<StyledProps>`
    width: 100%;
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @media (max-width: 1200px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    ${(p) =>
        p.flex &&
        css`
            display: flex;
        `};

    ${(p) =>
        p.$wrap &&
        css`
            flex-wrap: wrap;
        `};

    ${(p) =>
        !!p.direction &&
        css`
            flex-direction: ${p.direction};
        `};

    ${(p) =>
        !!p.justify &&
        css`
            justify-content: ${p.justify};
        `};

    ${(p) =>
        !!p.align &&
        css`
            align-items: ${p.align};
        `};

    ${(p) =>
        (p.large || p.size === ContainerSizes.LARGE) &&
        css`
            max-width: 1160px;
            @media (max-width: 1160px) {
                padding-left: 24px;
                padding-right: 24px;
            }
        `};

    ${(p) =>
        (p.medium || p.size === ContainerSizes.MEDIUM) &&
        css`
            max-width: 1000px;
            @media (max-width: 1000px) {
                padding-left: 24px;
                padding-right: 24px;
            }
        `};

    ${(p) =>
        (p.mediumSmall || p.size === ContainerSizes.MEDIUM_SMALL) &&
        css`
            max-width: 800px;
            @media (max-width: 800px) {
                padding-left: 24px;
                padding-right: 24px;
            }
        `};

    ${(p) =>
        (p.small || p.size === ContainerSizes.SMALL) &&
        css`
            max-width: 650px;
            @media (max-width: 650px) {
                padding-left: 24px;
                padding-right: 24px;
            }
        `};

    ${(p) =>
        p.smallFromTablet &&
        css`
            @media (max-width: 1024px) {
                max-width: 650px;
                padding-left: 24px;
                padding-right: 24px;
            }
        `};

    ${(p) =>
        (p.smaller || p.size === ContainerSizes.SMALLER) &&
        css`
            max-width: 550px;
            @media (max-width: 550px) {
                padding-left: 24px;
                padding-right: 24px;
            }
        `};

    ${(p) =>
        p.custom &&
        css`
            max-width: ${p.custom};
            @media (max-width: ${p.custom}) {
                padding-left: 24px;
                padding-right: 24px;
            }
        `};
`;
