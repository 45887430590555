import styled, { css } from 'styled-components';
import Link from 'next/link';

import { colors } from '/css';
import { ReactNode } from 'react';

interface ThemeProps {
    purple?: boolean;
    underlined?: boolean;
    orange?: boolean;
    large?: boolean;
    medium?: boolean;
    mediumSmall?: boolean;
    small?: boolean;
    tiny?: boolean;
}

interface BaseProps extends ThemeProps {
    children: ReactNode;
    external?: boolean;
}

interface LinkHrefProps extends BaseProps {
    href: string;
    to?: never;
    onClick?: never;
    target?: string;
}

interface LinkToProps extends BaseProps {
    href?: never;
    to: string;
    onClick?: never;
    target?: string;
}

interface LinkOnClickProps extends BaseProps {
    href?: never;
    to?: never;
    target?: never;
    onClick: (e: any) => void;
}

const LinkComponent = ({
    href,
    to,
    children,
    purple,
    underlined,
    orange,
    onClick,
    large,
    medium,
    mediumSmall,
    small,
    tiny,
    external,
    ...rest
}: LinkHrefProps | LinkToProps | LinkOnClickProps) => {
    if (!children) return null;

    const theme = {
        purple,
        underlined,
        orange,
        large,
        medium,
        mediumSmall,
        small,
        tiny,
    };

    if (onClick)
        return (
            <A
                tabIndex={0}
                {...theme}
                {...rest}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
            </A>
        );

    if (external)
        return (
            <A tabIndex={0} {...theme} {...rest} href={href}>
                {children}
            </A>
        );

    return (
        <Link prefetch={false} href={href || to || '/'} passHref legacyBehavior>
            <A tabIndex={0} {...theme} {...rest}>
                {children}
            </A>
        </Link>
    );
};

export default LinkComponent;

const A = styled.a<ThemeProps>`
    cursor: pointer;
    font-size: 20px;
    font-family: 'gellix-semi';
    transition: color 0.2s ease-in-out;
    position: relative;
    display: inline-block;
    ${({ purple }) => (purple ? `color: ${colors.purple}!important;` : ``)}

    @media(pointer: fine) {
        &:hover,
        &:active {
            color: ${colors.purple};
        }

        &:focus {
            //color: ${colors.orange};
            outline: none;
        }
    }

    ${({ underlined, orange }) =>
        underlined &&
        css`
            font-size: 17px;
            &:after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 3px;
                background: ${orange ? colors.orange : colors.purple};
                transition: background 0.2s ease-in-out;
            }
            &:hover,
            &:active {
                color: ${colors.black};
                &:after {
                    background: ${orange ? colors.purple : colors.orange};
                }
            }

            &:focus {
                color: ${colors.black};
                outline: none;
                &:after {
                    background: ${orange ? colors.purple : colors.orange};
                }
            }
        `};

    ${({ large }) =>
        large &&
        css`
            font-size: 28px;
            line-height: 38px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 24px;
                line-height: 34px;
            }
            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 28px;
            }
        `};

    ${({ medium }) =>
        medium &&
        css`
            font-size: 24px;
            line-height: 36px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 20px;
                line-height: 32px;
            }
            @media (max-width: 767px) {
                font-size: 17px;
                line-height: 26px;
            }
        `};

    ${({ mediumSmall }) =>
        mediumSmall &&
        css`
            font-size: 20px;
            line-height: 28px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 17px;
                line-height: 26px;
            }
            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 23px;
            }
        `};

    ${({ small }) =>
        small &&
        css`
            font-size: 17px;
            line-height: 26px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 17px;
                line-height: 26px;
            }
            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 23px;
            }
        `};

    ${({ tiny }) =>
        tiny &&
        css`
            font-size: 15px;
            line-height: 23px;
        `};
`;
