import { SimpleModuleType } from './frontModuleType';

import { UsedOn } from './frontModuleTemplate';
import ImageType from './frontImageType';

export enum PageTypes {
    PAGE = 'page',
    JOB = 'job',
    MODULE_TEMPLATE = 'module-template',
}

export type PageType = `${PageTypes}`;

export interface PageLikeObject {
    url?: string;
    _type?: string;
    _id?: number | string;
    slug?: string;
    id?: number | string;
    path?: string;
}

interface Page {
    _id: string;
    _type: PageType;
    id: number;
    parentId: number | null;
    draftId: number | null;
    published: boolean;
    title: string;
    slug: string;
    path: string;
    modules: SimpleModuleType[];
    seo: {
        title: string;
        description: string;
        keywords: string[];
        image: ImageType | null;
    };
    createdAt: string;
    updatedAt: string;
    background: string;
    usedOn?: UsedOn[];
}

export default Page;

export interface PartialPage extends Partial<Page> {}
