import { colors } from '/css';

const CircleInfoSolid = ({ color = colors.black, size = 18, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
        <g clipPath="url(#clip0_5049_24686)">
            <path
                d="M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM7.59375 11.8125H8.4375V9.5625H7.59375C7.12617 9.5625 6.75 9.18633 6.75 8.71875C6.75 8.25117 7.12617 7.875 7.59375 7.875H9.28125C9.74883 7.875 10.125 8.25117 10.125 8.71875V11.8125H10.4062C10.8738 11.8125 11.25 12.1887 11.25 12.6562C11.25 13.1238 10.8738 13.5 10.4062 13.5H7.59375C7.12617 13.5 6.75 13.1238 6.75 12.6562C6.75 12.1887 7.12617 11.8125 7.59375 11.8125ZM9 4.5C9.29837 4.5 9.58452 4.61853 9.79549 4.82951C10.0065 5.04048 10.125 5.32663 10.125 5.625C10.125 5.92337 10.0065 6.20952 9.79549 6.42049C9.58452 6.63147 9.29837 6.75 9 6.75C8.70163 6.75 8.41548 6.63147 8.20451 6.42049C7.99353 6.20952 7.875 5.92337 7.875 5.625C7.875 5.32663 7.99353 5.04048 8.20451 4.82951C8.41548 4.61853 8.70163 4.5 9 4.5Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_5049_24686">
                <rect width={size} height={size} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CircleInfoSolid;
