export const mobile = `@media (max-width: 768px)`;
export const aboveMobile = `@media (min-width: 769px)`;

export const tabile = `@media (max-width: 868px)`;
export const aboveTabile = `@media (min-width: 869px)`;
export const belowTabile = `@media (max-width: 867px)`;
export const onlyOnTabile = `@media (min-width: 868px) and (max-width: 1024px)`;

export const tablet = `@media (max-width: 1024px)`;
export const aboveTablet = `@media (min-width: 1024px)`;
export const belowTablet = `@media (max-width: 1023px)`;
export const onlyOnTablet = `@media (min-width: 768px) and (max-width: 1024px)`;

export const desktop = `@media (min-width: 1025px)`;
export const belowDesktop = `@media (max-width: 1024px)`;

export const breakpoint = (maxWidth: number) =>
    `@media (max-width: ${maxWidth}px)`;
