import { css } from 'styled-components';
import { ButtonStyleProps } from './Button.types';
import { colors } from '/css';

const newButtonVariants = {
    primary: (p: ButtonStyleProps) => css<ButtonStyleProps>`
        color: white;
        background: ${colors.purple};
        position: relative;
        overflow: visible;

        transition: background-color 0.2s ease-in-out;

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            left: -5px;
            top: -5px;
            box-sizing: content-box;
            border: 3px solid ${colors.purpleLighter4};
            border-radius: 17px;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.1s ease-in-out;
        }

        &:hover {
            background-color: ${colors.purpleDarker2};
        }

        &:active {
            color: rgba(255, 255, 255, 0.8);
        }

        &:focused:not(:active):not(:disabled) {
            :after {
                opacity: 1;
            }
        }

        &:disabled {
            background-color: #f7f7f7;
            color: #727272;
        }

        .button-icon svg {
            color: inherit;
        }
    `,
    secondary: (p: ButtonStyleProps) => css<ButtonStyleProps>``,
    'secondary-inverted': (p: ButtonStyleProps) => css<ButtonStyleProps>``,
    tertiary: (p: ButtonStyleProps) => css<ButtonStyleProps>``,
    'tertiary-inverted': (p: ButtonStyleProps) => css<ButtonStyleProps>``,
    soft: (p: ButtonStyleProps) => css<ButtonStyleProps>``,
    orange: (p: ButtonStyleProps) => css<ButtonStyleProps>``,
    black: (p: ButtonStyleProps) => css<ButtonStyleProps>``,
    grey: (p: ButtonStyleProps) => css<ButtonStyleProps>``,
};

export default newButtonVariants;
