import api from './api';

export default {
    sendVerificationCode: ({ phone }) =>
        api.post('auth/send-verification-code', { phone_number: phone }, {}),

    verifyPhone: (data) => api.post('auth/verify-phone-number', data, {}),

    createUser: (data) => api.post('auth/register', data, {}),

    logIn: ({ phone }) => api.post('auth/login', { phone_number: phone }, {}),

    logInWithToken: (token) => api.post('auth/login/token', { token }, {}),

    confirmCode: (data) => api.post('auth/confirm', data, null, true),

    getCurrentUser: (token) => {
        return api.post('auth/me', {}, api.authHeader(token));
    },

    refreshToken: (token) =>
        api.post('auth/refresh', {}, api.authHeader(token)),

    logOut: () => api.post('auth/logout', {}),
};
