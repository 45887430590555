import styled from 'styled-components';
import { HTMLAttributes, ReactNode } from 'react';
import CircleCheckSolid from '/components/icons/CircleCheckSolid';
import CircleExclamationSolid from '/components/icons/CircleExclamationSolid';
import TriangleExclamationSolid from '/components/icons/TriangleExclamationSolid';
import CircleInfoSolid from '/components/icons/CircleInfoSolid';

interface Props extends HTMLAttributes<HTMLElement> {
    type?: 'info' | 'success' | 'warning' | 'error';
    children: ReactNode;
}

const Alert = ({ type = 'info', children, ...rest }: Props) => (
    <Wrapper
        {...rest}
        className={['theme-' + type, rest.className ?? ''].join(' ')}
    >
        <div className="icon">
            {type === 'info' && <CircleInfoSolid />}
            {type === 'success' && <CircleCheckSolid />}
            {type === 'warning' && <TriangleExclamationSolid />}
            {type === 'error' && <CircleExclamationSolid />}
        </div>
        <div className="content">{children}</div>
    </Wrapper>
);

export default Alert;

const Wrapper = styled.div`
    font-size: 17px;
    font-family: var(--font-medium);
    text-align: left;
    padding: 12px 16px;
    border-radius: 8px;
    display: flex;

    .icon {
        position: relative;
        top: 2px;
        margin-right: 12px;
    }

    // The colors are hard coded here for now, as these are new "status" colors from Figma, and I don't want to overwrite
    // the colors.js status colors just yet. Once we have replaced all the status colors in colors.js we can use them in here
    &.theme-info {
        background-color: #f4f2ff;
        color: #3c2aa6;

        .icon svg path {
            fill: #3c2aa6;
        }
    }

    &.theme-success {
        background-color: #e8ffe3;
        color: #215b27;

        .icon svg path {
            fill: #215b27;
        }
    }

    &.theme-warning {
        background-color: #fefcdb;
        color: #76510b;

        .icon svg path {
            fill: #76510b;
        }
    }

    &.theme-error {
        background-color: #fff3ed;
        color: #7e0800;

        .icon svg path {
            fill: #7e0800;
        }
    }
`;
