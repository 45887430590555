import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const bugsnagClient = Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.NEXT_PUBLIC_ENVIRONMENT,
    enabledReleaseStages: ['staging', 'production'],
    enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
    logger: null,
    appVersion: process.env.NEXT_PUBLIC_BUILD_HASH,
});

export default bugsnagClient;
