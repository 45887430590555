import styled, { css } from 'styled-components';
import { colors } from '/css';

interface Props {
    direction: 'left' | 'right' | 'down' | 'up';
    color?: string;
}

const ChevronIcon = ({ direction, color = colors.purple, ...props }: Props) => {
    return (
        <StyledSvg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            direction={direction}
            {...props}
            color={color}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.26819 9L12.3831 13.116L16.4992 9L18.265 10.7707L12.3831 16.6515L6.5 10.7707L8.26819 9Z"
            />
        </StyledSvg>
    );
};

const StyledSvg = styled.svg<{ direction: Props['direction']; color: string }>`
    color: ${(p) => p.color};
    ${(p) =>
        p.direction === 'right' &&
        css`
            transform: rotate(-90deg);
        `};

    ${(p) =>
        p.direction === 'up' &&
        css`
            transform: rotate(180deg);
        `};

    ${(p) =>
        p.direction === 'left' &&
        css`
            transform: rotate(90deg);
        `};

    path {
        fill: currentColor;
    }
`;

export default ChevronIcon;
