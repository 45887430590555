import { create } from 'zustand';

interface PageStoreState {
    page: any;
}

const initialState = {
    page: null,
};

interface PageStoreStateMutators {
    setPage: (page: any) => void;
}

interface PageStore extends PageStoreState, PageStoreStateMutators {}

export const usePageState = create<PageStore>((set, get) => ({
    ...initialState,
    setPage: (page) =>
        set((state) => ({
            page: !page ? null : { ...(state.page || {}), ...page },
        })),
}));
