import take from 'ramda/src/take';
import takeLast from 'ramda/src/takeLast';
import styled from 'styled-components';
import Link from 'next/link';

import { colors } from '/css';
import Container from '/components/Container';
import { cookie } from '/lib/helpers';

export default function Footer({ menus = [] }) {
    const clearCookieConsent = () => {
        cookie.set('CookieConsent', '', -1);
        location.reload();
    };

    let menu = (
        (Array.isArray(menus) ? menus : []).find(
            (m) => m._key === 'footer-menu'
        )?.items || []
    ).map((l) => ({ url: l.url, title: l.label }));

    const menuLinks = (links) =>
        (links || []).map((link, i) => (
            <li key={i}>
                <Link prefetch={false} href={link.url}>
                    {link.title}
                </Link>
            </li>
        ));

    return (
        <Wrapper id="footer">
            <Container>
                <div className="row mb-60">
                    <div className="col">
                        <Link
                            prefetch={false}
                            href={'/'}
                            className="logo mb-32"
                        >
                            <img
                                src="/images/logo.svg"
                                alt="SpotOn logo"
                                title="SpotOn logo"
                            />
                        </Link>

                        <div className="elektro">
                            Levert av <br />
                            <a href="https://www.elektroimportoren.no">
                                Elektroimportøren
                            </a>
                        </div>
                    </div>
                    <div className="col row">
                        <ul>
                            {menuLinks(take(Math.ceil(menu.length / 2), menu))}
                        </ul>
                        <ul>
                            {menuLinks(
                                takeLast(Math.floor(menu.length / 2), menu)
                            )}
                        </ul>
                    </div>
                </div>
                <div className="row centered">
                    <div className="col copy">
                        &copy;2020
                        <Link prefetch={false} href={'/personvern'}>
                            Personvernerklæring
                        </Link>
                        <Link prefetch={false} href={'/avtalevilkar'}>
                            Avtalevilkår
                        </Link>
                        <a className="clickable" onClick={clearCookieConsent}>
                            Cookies 🍪
                        </a>
                    </div>
                    <div className="col">
                        <img
                            src="/images/klarna.svg"
                            title="klarna logo"
                            alt="klarna logo"
                        />
                    </div>
                </div>
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled.footer`
    border-top: 3px solid ${colors.lightGrey};
    background: #fff;
    position: relative;
    padding: 48px 0;
    .logo {
        display: inline-block;
        margin-bottom: 25px;
    }
    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &.centered {
            align-items: center;
        }
    }
    .col {
        font-size: 17px;
        font-family: 'gellix-semi';
        line-height: 22px;
        @media (max-width: 767px) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        ul {
            margin-left: 60px;
            @media (max-width: 767px) {
                margin-left: 0;
                width: 100%;
            }
            li {
                margin-bottom: 18px;
                @media (max-width: 767px) {
                    width: 100%;
                    text-align: center;
                }
                .pur {
                    color: ${colors.purple};
                }
            }
        }
        .elektro {
            font-size: 15px;
            @media (max-width: 767px) {
                width: 100%;
                text-align: center;
                margin-bottom: 40px;
            }
            a {
                color: ${colors.orange};
            }
        }
        &.copy {
            font-size: 13px;
            color: #9f9ea9;
            @media (max-width: 767px) {
                display: flex;
                order: 2;
                padding-top: 32px;
            }
            a {
                margin-left: 8px;
                font-size: 13px;
                color: #9f9ea9;
            }
        }
    }
`;
