const formatPrice = (val: number, withPrefix = true): string => {
    if (isNaN(Math.ceil(val))) return '';
    return (
        (withPrefix ? 'kr ' : '') +
        String(Math.ceil(val)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    );
};

export const formatDiscountPrice = (val: number) => {
    const price = formatPrice(val).replace('-', '');
    return price ? `- ${price}` : '';
};

export const formatDiscountGrossPrice = (val: number) => {
    const price = formatDiscountPrice(val);
    return price ? `${price} Inkl. mva` : '';
};

export default formatPrice;
