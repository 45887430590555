import PageDraft, {
    DraftInfo,
    SomeOfPageDraft,
} from '/front/types/frontPageDraft';
import { Error } from '/types/apiResponseTypes';
import api from '/api';

const frontDraftApi = {
    get: async ({
        pageId,
        draftId,
    }: {
        pageId: PageDraft['pageId'];
        draftId?: PageDraft['id'];
    }): Promise<DraftInfo[] | PageDraft | Error> => {
        const resp = !draftId
            ? await api.getSecure(`page/${pageId}/draft`)
            : await api.getSecure(`page/${pageId}/draft/${draftId}`);

        if ('error' in resp) return resp;

        return !draftId ? (resp.data as DraftInfo[]) : (resp as PageDraft);
    },
    post: async (
        pageId: PageDraft['pageId'],
        data: SomeOfPageDraft
    ): Promise<PageDraft | Error> => {
        const resp = await api.post(`page/${pageId}/draft`, data);

        if ('error' in resp) return resp;

        return resp;
    },
    patch: async (
        pageId: PageDraft['pageId'],
        draftId: PageDraft['id'],
        data: SomeOfPageDraft
    ): Promise<PageDraft | Error> => {
        const resp = await api.patch(`page/${pageId}/draft/${draftId}`, data);

        if ('error' in resp) return resp;

        return resp;
    },
    delete: async (
        pageId: PageDraft['pageId'],
        draftId: PageDraft['id']
    ): Promise<{} | Error> => {
        const resp = await api.delete(`page/${pageId}/draft/${draftId}`);

        if ('error' in resp) return resp;

        return resp;
    },
};

export default frontDraftApi;
