import { UserRole, AnyUserType } from '/types/userTypes';
import { userIs } from '/lib/helpers';
import { useMemo } from 'react';
import { useHydration } from '/store/store.helpers';
import { useUserState } from '/store/userStore';

/*
 This hook accepts _user as optional argument
 In some cases - like when used in combination with withAuth component
 it's usefull to have user state right away before store hydration
 (withAuth provides user on first render so we want to use it before store hydrates)
 */
export default function useUserIs(_user?: AnyUserType): {
    hydrated: boolean;
    ofUuid: (uuid: string) => boolean;
    globalAdmin: boolean;
    caseworker: boolean;
    inspector: boolean;
    contractorAdmin: boolean;
    electrician: boolean;
    electricianOrContractor: boolean;
    storeEmployee: boolean;
    customer: boolean;
    customerService: boolean;
    salesPartnerSeller: boolean;
    salesPartnerAdmin: boolean;
    salesPartnerDepartmentAdmin: boolean;
} {
    const user = useUserState((state) => state.user);

    const hydrated = useHydration();

    const is = (role: UserRole | UserRole[]): boolean => {
        return userIs(_user || user, role) || false;
    };

    return useMemo(
        () => ({
            hydrated: hydrated,
            ofUuid: (uuid: string) => (_user || user)?.uuid === uuid,
            globalAdmin: is(UserRole.GLOBAL_ADMIN),
            caseworker: is(UserRole.CASEWORKER),
            inspector: is(UserRole.INSPECTOR),
            contractorAdmin: is(UserRole.CONTRACTOR_ADMIN),
            electrician: is(UserRole.ELECTRICIAN),
            electricianOrContractor: is([
                UserRole.ELECTRICIAN,
                UserRole.CONTRACTOR_ADMIN,
            ]),
            storeEmployee: is(UserRole.STORE_EMPLOYEE),
            customer: is(UserRole.CUSTOMER),
            customerService: is(UserRole.CUSTOMER_SERVICE),
            salesPartnerAdmin: is(UserRole.SALES_PARTNER_ADMIN),
            salesPartnerDepartmentAdmin: is(
                UserRole.SALES_PARTNER_DEPARTMENT_ADMIN
            ),
            salesPartnerSeller: is(UserRole.SALES_PARTNER_SELLER),
        }),
        [hydrated, _user, user]
    );
}
