import snakecaseKeys from 'snakecase-keys';

import api from './api';

const orderApi = {
    cart: {
        create: (data = {}) => api.post(`cart`, snakecaseKeys(data)),

        get: (cartUuid) => api.get(`cart/${cartUuid}`),

        update: (cartUuid, data = {}) =>
            api.patch(`cart/${cartUuid}`, snakecaseKeys(data)),

        startReminders: (cartUuid, data = {}) =>
            api.post(`cart/${cartUuid}/reminders`, data),

        startRemindersBySeller: (cartUuid, data = {}) =>
            api.post(
                `cart/${cartUuid}/reminders-by-seller`,
                data,
                undefined,
                true
            ),

        stopReminders: (cartUuid) => api.delete(`cart/${cartUuid}/reminders`),

        lineItem: {
            create: (cartUuid, lineItem) =>
                api.post(`cart/${cartUuid}/line-item`, snakecaseKeys(lineItem)),

            get: (cartUuid, lineItemId = '') =>
                api.get(`cart/${cartUuid}/line-item/${lineItemId}`),

            update: (
                cartUuid,
                lineItemId = 0,
                lineItem,
                caseConversion = true
            ) =>
                api.patch(
                    `cart/${cartUuid}/line-item/${lineItemId}`,
                    caseConversion ? snakecaseKeys(lineItem) : lineItem
                ),

            delete: (cartUuid, lineItemId = 0) =>
                api.delete(`cart/${cartUuid}/line-item/${lineItemId}`),
        },
    },

    bookProject: (cartUuid, data = {}) =>
        api.post(`cart/${cartUuid}/book`, snakecaseKeys(data)),
    bookProjectBySeller: (cartUuid, data = {}) =>
        api.post(
            `cart/${cartUuid}/book-by-seller`,
            snakecaseKeys(data),
            undefined,
            true
        ),
};

export default orderApi;
