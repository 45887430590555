import config from './config';

export default function _(str: string = '', ...rest: string[]) {
    const string = (config.translations || {})[str] || str;

    return string.replace(/\$\d+/g, (m: string) => {
        const n = +m.slice(1);
        return rest[n - 1] || m;
    });
}

// _('Hello $1! It\'s your first day at $2. You are $3', 'Kitty', 'School')
