import { Error } from '/types/apiResponseTypes';
import ModuleTemplate from '/front/types/frontModuleTemplate';
import { toSpotonModuleFormat, toFrontModuleFormat } from './frontPageApi';
import api from '/api';
import { SpotonSimpleModuleType } from '/front/types/frontModuleType';

export interface SpotonModuleTemplate
    extends Omit<
        ModuleTemplate,
        'used_on' | 'created_at' | 'updated_at' | 'data'
    > {
    used_on: ModuleTemplate['usedOn'];
    created_at: ModuleTemplate['createdAt'];
    updated_at: ModuleTemplate['updatedAt'];
    data: SpotonSimpleModuleType[];
}

export interface SpotonFormData {
    title: SpotonModuleTemplate['title'];
    data: SpotonModuleTemplate['data'];
    preview_id: number;
}

export interface FormData {
    title: ModuleTemplate['title'];
    data: ModuleTemplate['data'];
    previewId: number;
}

const toFrontTemplate = (template: SpotonModuleTemplate): ModuleTemplate => ({
    ...template,
    data: (template.data || []).map(toFrontModuleFormat),
});

const toSpotonFormData = (formData: FormData): SpotonFormData => ({
    title: formData.title,
    preview_id: formData.previewId,
    data: formData.data.map(toSpotonModuleFormat),
});

const frontModuleTemplateApi = {
    index: async (): Promise<ModuleTemplate[] | Error> => {
        const resp = await api.getSecure(`module-template`);

        if ('error' in resp) return { error: resp.error } as Error;

        return resp.map(toFrontTemplate);
    },
    single: async (
        id: number,
        token?: string
    ): Promise<ModuleTemplate | Error> => {
        const resp = await api.getSecure(`module-template/${id}`, token);

        if ('error' in resp) return { error: resp.error } as Error;

        return toFrontTemplate(resp);
    },
    create: async (data: FormData): Promise<ModuleTemplate | Error> => {
        const resp = await api.post(`module-template`, toSpotonFormData(data));

        if ('error' in resp) return { error: resp.error } as Error;

        return toFrontTemplate(resp);
    },
    update: async (
        id: number,
        data: FormData
    ): Promise<ModuleTemplate | Error> => {
        const resp = api.patch(`module-template/${id}`, toSpotonFormData(data));

        if ('error' in resp) return { error: resp.error } as Error;

        return toFrontTemplate(resp);
    },
    delete: (id: number): Promise<{} | Error> =>
        api.delete(`module-template/${id}`),
};

export default frontModuleTemplateApi;
