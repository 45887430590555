import Heading, { HeadingProps } from '../typography/Heading';

interface Props extends HeadingProps {}

const ModalHeader = ({ className, ...props }: Props) => (
    <Heading
        size="large"
        centered
        className={`mb-24 ${className}`}
        as="h6"
        {...props}
    />
);

export default ModalHeader;
