const camelToSnake = (str: string) => {
    return str
        .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
        .replace(/([a-z0-9])([A-Z])/, '$1_$2')
        .toLowerCase();
};

type QueryObject = {
    [key: string]: any;
}

const objectToQuery = (obj: QueryObject) =>
    Object.keys(obj || {})
        .filter((key) => obj[key])
        .map((key) => {
            if (Array.isArray(obj[key])) {
                return obj[key]
                    .filter((val: any) => val)
                    .map((val: any) => `${camelToSnake(key)}[]=${val}`)
                    .join('&');
            }
            return `${camelToSnake(key)}=${obj[key]}`;
        })
        .filter((val) => val)
        .join('&');

export default objectToQuery;