const isSupported = (getStorage: () => any) => {
    try {
        const key = '__test__';
        getStorage().setItem(key, key);
        getStorage().removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
};

export default isSupported;