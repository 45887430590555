const cookie = {
    set: (cname: string, cvalue: any, exdays = 1) => {
        if (typeof window === 'undefined') return false;
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
    get: (cname: string) => {
        if (typeof window === 'undefined') return false;
        const name = cname + '=';
        return (
            document.cookie
                .split(';')
                .map((s) => s.trim())
                .filter((s) => ~s.indexOf(name))
                .map((s) => s.replace(name, ''))[0] || ''
        );
    },
    getFromString: (cname: string, string?: string) => {
        if (!string) return '';
        const name = cname + '=';
        return (
            string
                .split(';')
                .map((s) => s.trim())
                .filter((s) => ~s.indexOf(name))
                .map((s) => s.replace(name, ''))[0] || ''
        );
    },
};

export default cookie;
