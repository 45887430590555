import React, { ReactNode, useEffect } from 'react';
import styled, { css } from 'styled-components';

import IconButton from './buttons/IconButton';
import Icon from './Icon';
import Portal from '/components/Portal';
import { effects, colors } from '/css';

interface Props {
    children: ReactNode;
    closeRequestCallback?: () => void;
    closeButton?: boolean;
    width?: string;
    className?: string;
    white?: boolean;
    style?: any;
    closeIconStyle?: any;
    usePortal?: boolean;
    zIndex?: number;
    overflowHidden?: boolean;
    wrapperClassName?: string;
}

const Modal = ({
    children,
    closeRequestCallback,
    closeButton = true,
    width = '',
    className,
    white = false,
    style = {},
    closeIconStyle = {},
    usePortal = false,
    zIndex = 9998,
    overflowHidden,
    wrapperClassName,
}: Props) => {
    useEffect(() => {
        const onKeyup = (e: any) => {
            if (e.key === 'Escape' && !!closeRequestCallback)
                closeRequestCallback();
        };
        window.addEventListener('keyup', onKeyup);
        document.getElementsByTagName('html')[0].classList.add('oh');
        return () => {
            window.removeEventListener('keyup', onKeyup);
            document.getElementsByTagName('html')[0].classList.remove('oh');
        };
    }, []);

    const OptionalPortal = usePortal ? Portal : 'div';

    return (
        <OptionalPortal>
            <Wrapper
                id="modal"
                closeOnOverlayClick={closeButton !== false}
                onMouseDown={(e: any) => {
                    if (
                        closeRequestCallback &&
                        ['modal', 'modal-flex-wrap'].includes(e.target.id)
                    ) {
                        e.stopPropagation();
                        closeRequestCallback();
                    }
                }}
                className={`pt-48 pb-48 ${wrapperClassName || ''}`}
                white={white}
                zIndex={zIndex}
            >
                <div id="modal-flex-wrap">
                    <div>
                        <ModalEl
                            className={className}
                            id="modal-window"
                            width={width}
                            white={white}
                            style={style}
                            onClick={(e) => e.stopPropagation()}
                            overflowHidden={overflowHidden}
                        >
                            {children}
                        </ModalEl>
                        {closeButton !== false && (
                            <IconButton
                                className="close-icon"
                                variant="tertiary"
                                size="small"
                                icon={<Icon icon="close" />}
                                onClick={closeRequestCallback}
                                style={closeIconStyle}
                            />
                        )}
                    </div>
                </div>
            </Wrapper>
        </OptionalPortal>
    );
};

export default Modal;

const Wrapper = styled.div<{
    closeOnOverlayClick: boolean;
    white: boolean;
    zIndex: number;
}>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) =>
        props.white ? `rgba(255,255,255,.9)` : `rgba(0,0,0,.48)`};
    z-index: ${(p) => p.zIndex};
    overflow: auto;
    padding-left: 16px;
    padding-right: 16px;
    ${(props) =>
        props.closeOnOverlayClick
            ? `cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAAQBJREFUSA2tldENgjAQQIkJI8AejuAE/jML/25jHMT45QYO4AIm+K56eiS00F4vuVwJ6XttaWnTfGOapp22a1TlBSgPR6BXal8JPsI5w2sbMZE3UuJOuiT0HwVEvMhDGDCNnhS4RLGEvhY+zFaDly5JEq6mUskmeKkkC54rKYJvlbjga5Iq8ITkhEBC9vl8K2qn3ArIbuG6cB0MVB25CB6k68QrN1Rg9oQKXKL4xKfgA2C7XD4JMDvy3wetIonBdXouyRo8Ien0XbRuhStgYSZxSS48S1IKN5IOhr20/jPxwpMS4HInX8gq/xY4ek6etPdBTqMlPxe0DsdRYclyBfgbXpalja44wn4AAAAASUVORK5CYII='), auto;`
            : ``};

    .close-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        z-index: 900;
        background: #fff;
        width: 36px;
        height: 36px;
        padding: 0px;
        border-color: transparent !important;
        @media (max-width: 480px) {
            top: 4px;
            right: 4px;
        }
    }

    #modal-flex-wrap {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
            position: relative;
        }
    }
`;

const ModalEl = styled.div<{
    width?: string;
    white?: boolean;
    overflowHidden?: boolean;
}>`
    background: #fff;
    width: ${(props) => props.width || '660px'};
    max-width: 90vw;
    cursor: default;
    position: relative;
    &::-webkit-scrollbar {
        display: none;
    }
    border-radius: 12px;

    ${(p) =>
        p.overflowHidden &&
        css`
            overflow: hidden;
        `};

    ${(p) =>
        p.white &&
        css`
            ${effects.shadowHover};
            border: 1px solid ${colors.blueGrey};
        `};
`;
