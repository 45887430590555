import orderApi from '/api/order';
import { useOrderState } from '/store/orderStore';

export default function useCartReservation() {
    const cart = useOrderState((state) => state.cart);
    const setOrderCart = useOrderState((state) => state.setOrderCart);
    const clearOrderCart = useOrderState((state) => state.clearOrderCart);

    const setCartReservationUuid = async (uuid) => {
        const patch = await orderApi.cart.update(cart.uuid, {
            reservation_uuid: uuid,
        });
        if (['CART_EXPIRED', 'CART_BOOKED'].includes(patch?.code)) {
            return clearOrderCart();
        }

        setOrderCart((await orderApi.cart.get(cart.uuid)).data);
    };

    const clearCartReservation = async () => await setCartReservationUuid(null);

    return {
        setCartReservationUuid,
        clearCartReservation,
    };
}
