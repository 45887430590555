import { useEffect, useRef } from 'react';

const useOnClickOutside = <T extends HTMLDivElement>(
    callback: () => void,
    disabled: boolean = false,
    isShadowRoot: boolean = false,
    ignoredTargetClasses: string[] = []
) => {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            const target = isShadowRoot
                ? event.composedPath()[0]
                : event.target;

            const isIgnored = ignoredTargetClasses.some((className) => {
                // Check if the target is ignored or if a parent of it has the ignored class
                return (
                    target instanceof HTMLElement &&
                    (target.classList.contains(className) ||
                        target.closest(`${className}`))
                );
            });

            if (
                target instanceof HTMLElement &&
                ref.current &&
                !ref.current.contains(target) &&
                !isIgnored
            ) {
                callback();
            }
        };

        if (!disabled) {
            const isTouch =
                'ontouchstart' in window || navigator.maxTouchPoints > 0;

            const mouseEvent = 'mousedown';
            const touchEvent = 'touchend';

            document.addEventListener(mouseEvent, handleClickOutside);
            if (isTouch) {
                document.addEventListener(touchEvent, handleClickOutside);
            }

            return () => {
                document.removeEventListener(mouseEvent, handleClickOutside);
                if (isTouch) {
                    document.removeEventListener(
                        touchEvent,
                        handleClickOutside
                    );
                }
            };
        }
    }, [ref, callback, disabled]);

    return ref;
};

export default useOnClickOutside;
