interface Distance {
    desktop: number;
    tablet: number;
    mobile: number;
}

interface DistanceHelperClass {
    class: string;
    prop: string | string[];
}

const distanceClasses: DistanceHelperClass[] = [
    { class: 'mb', prop: 'margin-bottom' },
    { class: 'mt', prop: 'margin-top' },
    { class: 'mr', prop: 'margin-right' },
    { class: 'ml', prop: 'margin-left' },
    { class: 'm', prop: 'margin' },
    { class: 'mv', prop: ['margin-top', 'margin-bottom'] },
    { class: 'mh', prop: ['margin-left', 'margin-right'] },
    { class: 'pb', prop: 'padding-bottom' },
    { class: 'pt', prop: 'padding-top' },
    { class: 'pr', prop: 'padding-right' },
    { class: 'pl', prop: 'padding-left' },
    { class: 'p', prop: 'padding' },
    { class: 'pv', prop: ['padding-top', 'padding-bottom'] },
    { class: 'ph', prop: ['padding-left', 'padding-right'] },
    { class: 'h', prop: 'height' },
];

const distances: Distance[] = [
    { desktop: 180, tablet: 140, mobile: 100 },
    { desktop: 150, tablet: 100, mobile: 80 },
    { desktop: 120, tablet: 80, mobile: 60 },
    { desktop: 100, tablet: 80, mobile: 60 },
    { desktop: 80, tablet: 60, mobile: 48 },
    { desktop: 60, tablet: 48, mobile: 32 },
    { desktop: 48, tablet: 36, mobile: 24 },
    { desktop: 40, tablet: 30, mobile: 20 },
    { desktop: 32, tablet: 24, mobile: 16 },
    { desktop: 24, tablet: 16, mobile: 12 },
    { desktop: 16, tablet: 12, mobile: 8 },
    { desktop: 12, tablet: 8, mobile: 4 },
    { desktop: 8, tablet: 6, mobile: 4 },
    { desktop: 4, tablet: 4, mobile: 4 },
];

const getPropCss = (c: DistanceHelperClass, d: number): string => {
    const getSinglePropCss = (prop: string, d: number) => `
        ${prop}: ${d}px;
    `;

    if (Array.isArray(c.prop)) {
        return c.prop.map((p) => getSinglePropCss(p, d)).join('\n');
    }

    return getSinglePropCss(c.prop, d);
};

const getResponsiveStyles = (c: DistanceHelperClass, d: Distance) => `
    .${c.class}-${d.desktop} {
        ${getPropCss(c, d.desktop)}

        @media (min-width: 768px) and (max-width: 1024px) {
            ${getPropCss(c, d.tablet)}
        }
    
        @media(max-width: 767px) {
            ${getPropCss(c, d.mobile)}
        }
    }
`;

export const responsiveDistanceStyles = distanceClasses
    .map((c) => distances.map((d) => getResponsiveStyles(c, d)).join('\n'))
    .join('\n');

const getStaticDistanceStyles = (c: DistanceHelperClass, d: Distance) => `
    .${c.class}-${d.desktop}-all {
        ${getPropCss(c, d.desktop)}
    }
`;

export const staticDistanceStyles = distanceClasses
    .map((c) => distances.map((d) => getStaticDistanceStyles(c, d)).join('\n'))
    .join('\n');
