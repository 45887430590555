import styled from 'styled-components';
import { ReactNode, ElementType, HTMLAttributes } from 'react';

import { colors } from '/css';
import { headingSizes } from '/css/GlobalStyles';

interface Sizes {
    xxl?: boolean; // renders h1 (110/120) - DEFAULT | 72/80 | 32/38
    xl?: boolean; // renders h2 (72/80) | 60/62 | 32/38
    large?: boolean; // renders h3 (42/46) | 36/42 | 26/32
    medium?: boolean; // renders h4 (28/36) | 24/30 | 20/26
    small?: boolean; // renders h5 (24/28) | 20/28 | 17/22
}

interface Props extends Sizes, HTMLAttributes<HTMLElement> {
    children: ReactNode;
    html?: boolean; // renders children string as HTML
    centered?: boolean;
    tag?: string;
    light?: boolean;
}

const getTag = (
    tag: string | undefined,
    { xxl, xl, large, medium, small }: Sizes
): ElementType => {
    const tags = { h1: H1, h2: H2, h3: H3, h4: H4, h5: H5, div: Div };
    const tagName = tag?.toLowerCase() || 'h1';

    if (tag && tags[tagName as keyof typeof tags]) {
        return tags[tagName as keyof typeof tags];
    }

    if (xxl) return H1;
    if (xl) return H2;
    if (large) return H3;
    if (medium) return H4;
    if (small) return H5;

    return H1;
};

const getSize = ({ xxl, xl, large, medium, small }: Sizes) => {
    if (xxl) return 'xxl';
    if (xl) return 'xl';
    if (large) return 'large';
    if (medium) return 'medium';
    if (small) return 'small';

    return 'xxl';
};

const Heading = ({
    children,
    xxl,
    xl,
    large,
    medium,
    small,
    tag,
    light,
    html,
    centered,
    ...rest
}: Props) => {
    const Tag = getTag(tag, { xxl, xl, large, medium, small });
    const size = getSize({ xxl, xl, large, medium, small });

    return html ? (
        <Tag
            {...rest}
            size={size}
            light={light}
            centered={centered}
            dangerouslySetInnerHTML={{ __html: children }}
        />
    ) : (
        <Tag size={size} light={light} centered={centered} {...rest}>
            {children}
        </Tag>
    );
};

export default Heading;

interface Style {
    size?: string;
    light?: boolean;
    centered?: boolean;
    children: ReactNode;
}

export const headingStyles = ({ size, light, centered }: Style) => {
    const styles = headingSizes.filter((s) => s.name === size)[0];

    if (!styles) return ``;

    return `
        font-family: ${light ? `gellix-semi` : `gellix-bold`};
        color: ${colors.black};
        position:relative;
        font-size: ${styles.desktop.fs}px;
        line-height: ${styles.desktop.lh}px;
        text-align: ${centered ? 'center' : 'left'};
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: ${styles.tablet.fs}px;
            line-height: ${styles.tablet.lh}px;
        }
        @media(max-width: 767px) {
            font-size: ${styles.mobile.fs}px;
            line-height: ${styles.mobile.lh}px;
        }
    `;
};

const H1 = styled.h1`
    ${headingStyles}
`;

const H2 = styled.h2`
    ${headingStyles}
`;

const H3 = styled.h3`
    ${headingStyles}
`;

const H4 = styled.h4`
    ${headingStyles}
`;

const H5 = styled.h5`
    ${headingStyles}
`;

const Div = styled.div`
    ${headingStyles}
`;
