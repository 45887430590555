import mediaApi from '/api/media';
import ImageType, { FetchImageProps } from '../../front/types/frontImageType';
import { Error } from '/types/apiResponseTypes';

const toFrontImageFormat = (image: ImageType): ImageType => ({
    _id: image.id,
    crop: null,
    hotspot: null,
    metadata: image.metadata,
    originalFilename: image.originalFilename,
    category: image.category,
    size: image.size,
    url: image.url,
    title: '',
    alt: '',
});

const frontMediaApi = {
    get: async ({
        id,
        search,
        category,
        page = 1,
        perPage = 48,
        withMeta,
    }: FetchImageProps): Promise<
        ImageType[] | { data: ImageType[]; meta: any } | Error
    > => {
        const resp = await mediaApi.index({
            id: id,
            page: page,
            perPage: perPage,
            type: 'cms-image',
            category: category,
            search: search,
        });
        if (resp.error) return { error: resp.error };
        return withMeta
            ? {
                  data: resp.data.map(toFrontImageFormat),
                  meta: resp.meta,
              }
            : resp.data.map(toFrontImageFormat);
    },
    put: async ({
        file,
        hidden,
    }: {
        file: File;
        hidden?: boolean;
    }): Promise<ImageType | Error> => {
        const resp = await (!hidden
            ? mediaApi.cmsUpload(file)
            : mediaApi.customerUpload(file));
        if (resp.error) return { error: resp.error };
        return toFrontImageFormat(resp.data);
    },
    patch: async ({ id, data }: { id: number; data: Partial<ImageType> }) => {
        const resp = await mediaApi.patch(id, data);
        if (resp.error) return { error: resp.error };
        return resp;
    },
    delete: async ({ id }: { id: number }) => {
        const resp = await mediaApi.delete(id);
        if (resp.error) return { error: resp.error };
        return resp;
    },
};

export default frontMediaApi;
