import { HTMLAttributes, useState } from 'react';
import LoginModalContext from './LoginModal.context';
import LoginModal from './LoginModal';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const LoginModalProvider = ({ children, ...props }: Props) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string | undefined>();

    const show = (message?: string) => {
        if (message) setMessage(message);
        setOpen(true);
    };

    const hide = () => {
        setOpen(false);
        setMessage(undefined);
    };

    return (
        <LoginModalContext.Provider value={{ open, show, hide, message }}>
            {children}
            {open && (
                <LoginModal
                    onClose={() => setOpen(false)}
                    description={message}
                />
            )}
        </LoginModalContext.Provider>
    );
};

export default LoginModalProvider;
