import { NextPage, NextPageContext } from 'next';

import getMenus from '/lib/utils/getMenus';
import Menu from '/front/types/frontMenuType';

type HOCProps = {
    menus: Menu[];
};

function withMenus<P extends HOCProps>(WrappedComponent: NextPage<P>) {
    const MenuProvider: NextPage<P> = (props) => {
        return <WrappedComponent {...props} />;
    };

    MenuProvider.getInitialProps = async (ctx: NextPageContext): Promise<P> => {
        let wrappedComponentProps = {} as P;
        if (WrappedComponent.getInitialProps) {
            wrappedComponentProps = await WrappedComponent.getInitialProps(ctx);
        }

        return {
            ...wrappedComponentProps,
            menus: await getMenus(),
        };
    };

    return MenuProvider;
}

export default withMenus;
