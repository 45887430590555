import { AnyUserType, SimpleUserType, UserRole } from '/types/userTypes';

const userIs = (
    user: AnyUserType | undefined | null,
    roles: UserRole | UserRole[]
) => {
    if (!user) return false;

    const userRoles = user._type === 'simple' ? user.roles : user.activeRoles;

    if (!userRoles || !userRoles.length) return false;

    const allowedRoles: UserRole[] = Array.isArray(roles) ? roles : [roles];

    if (allowedRoles.length === 1 && allowedRoles[0] === UserRole.CUSTOMER) {
        // Regular customers can have only one role but staff members can also be customers.
        // When checking if user is customer we are interested only in regular customers.
        return userRoles.length === 1 && userRoles[0] === UserRole.CUSTOMER;
    }

    return userRoles.some((r) => allowedRoles.includes(r));
};

export default userIs;
