export interface ValidationErrors {
    [key: string]: string[];
}

export enum ApiErrorCodes {
    UNAUTHORIZED = 'unauthorized',
}

export interface ApiErrorResponse {
    error: string;
    errors: any;
    code: number;
}

export interface ApiValidationErrorResponse extends ApiErrorResponse {
    errors: ValidationErrors;
    code: 422;
}

export type ApiErrorResponseType =
    | ApiErrorResponse
    | ApiValidationErrorResponse;

export const handleValidationError = (
    e: any,
    cb: (e: ApiValidationErrorResponse) => void
): boolean => {
    if (e?.code === 422) {
        cb(e);
        return true;
    }

    return false;
};

export const handleApiError = (e: any, cb: (e: ApiErrorResponse) => void) => {
    if (e?.error) cb(e);
};

export const handleApi404 = (e: any, cb: (e: ApiErrorResponse) => void) => {
    if (e?.code === 404) {
        cb(e);
        return true;
    }

    return false;
};

export interface Error {
    error: string;
}

export interface PaginatedResponse<Resource> {
    data: Resource[];
    links: {
        first: string;
        last: string;
        next: string | null;
        prev: string | null;
    };
    meta: {
        currentPage: number;
        from: number;
        lastPage: number;
        perPage: number;
        to: number;
        total: number;
    };
}
