import moment from 'moment';

export default function formatDateRange(
    from: string,
    to: string,
    withTime?: boolean
) {
    const start = moment(from).locale('nb');
    const end = moment(to).locale('nb');
    const fullDateFormat = withTime ? 'D MMM YYYY [Kl.] HH:mm' : 'D MMM YYYY';
    const dateWithMonthFormat = withTime ? 'D MMM [Kl.] HH:mm' : 'D MMM';
    const dayOnlyFormat = withTime ? 'D [Kl.] HH:mm' : 'D';

    if (start.isSame(end, 'day')) {
        // If dates are the same
        return start.format(fullDateFormat);
    } else if (start.isSame(end, 'month')) {
        // If dates are from same month
        if (withTime) {
            return `${start.format(dateWithMonthFormat)} - ${end.format(
                fullDateFormat
            )}`;
        } else {
            return `${start.format(dayOnlyFormat)} - ${end.format(
                fullDateFormat
            )}`;
        }
    } else if (start.isSame(end, 'year')) {
        // If dates are from different months but the same year
        return `${start.format(dateWithMonthFormat)} - ${end.format(
            fullDateFormat
        )}`;
    } else {
        // If dates are from different years
        return `${start.format(fullDateFormat)} - ${end.format(
            fullDateFormat
        )}`;
    }
}
