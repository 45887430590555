import React, { useState } from 'react';
import Heading from '/components/ui/Heading';
import Paragraph from '/components/ui/Paragraph';
import Button from './buttons/Button';
import Modal from '/components/ui/Modal';
import isObject from '/lib/utils/isObject';
import HelpButton from '/components/ui/HelpButton';
import dynamic from 'next/dynamic';

const Slider = dynamic(() => import('/components/ui/Slider'), { ssr: false });

interface Image {
    url: string;
    alt?: string;
}

const isWidgetContext = !!process.env.IS_WIDGET_CONTEXT;

interface HelpProps {
    title?: string;
    text?: string;
    images?: (string | Image)[];
    children?: React.ReactNode;
}

const Help: React.FC<HelpProps> = ({ title, text, images, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const openChat = () => {
        const btn = document.getElementById('itx-chat-btn');
        const btnInner = btn ? btn.getElementsByTagName('div')[0] : undefined;
        if (btnInner) btnInner.click();
        closeModal();
    };

    return (
        <>
            <HelpButton onClick={openModal} />
            {isOpen && (
                <Modal
                    closeRequestCallback={closeModal}
                    className="p-32 help-popup"
                >
                    {title && (
                        <Heading small className="mb-24">
                            {title}
                        </Heading>
                    )}
                    {text && (
                        <Paragraph className="mb-32" weak html>
                            {text}
                        </Paragraph>
                    )}
                    {children}
                    {images && Array.isArray(images) && images.length > 0 && (
                        <Slider
                            images={
                                images.map((img) => ({
                                    url: isObject(img)
                                        ? (img as Image).url
                                        : img,
                                })) as Image[]
                            }
                            className="mb-24"
                            id="help-images"
                            bulletAlign="flex-start"
                            autoplay={false}
                        />
                    )}
                    <Button
                        variant="secondary"
                        size="small"
                        onClick={closeModal}
                    >
                        Ok, lukk
                    </Button>
                    {!isWidgetContext && (
                        <Button
                            variant="tertiary"
                            style={{ marginLeft: '12px' }}
                            size="small"
                            onClick={openChat}
                        >
                            Chat med oss
                        </Button>
                    )}
                </Modal>
            )}
        </>
    );
};

export default Help;
