import { Component } from 'react';
import styled from 'styled-components';
import ErrorPage from 'next/error';

import bugsnagClient from '/lib/bugsnag';
import { colors } from '/css';
import { goTo } from '/lib/helpers';
import Layout from '/components/Layout';
import Button from '../components/ui/buttons/Button';
import getMenus from '/lib/utils/getMenus';

class Error extends Component {
    static async getInitialProps(ctx) {
        if (ctx.err) bugsnagClient.notify(ctx.err);

        return {
            menus: await getMenus(),
            ...ErrorPage.getInitialProps(ctx),
        };
    }

    render() {
        const { statusCode, menus } = this.props;

        return (
            <Layout menus={menus}>
                <Wrapper className="pt-80 pb-80">
                    <p>Ups! Something went wrong</p>
                    <ErrorCode>{statusCode || '¯\\_(ツ)_/¯'}</ErrorCode>
                    <Button
                        variant="secondary"
                        size="small"
                        onClick={() => goTo('/')}
                    >
                        Home page
                    </Button>
                </Wrapper>
            </Layout>
        );
    }
}

export default Error;

const Wrapper = styled.div`
    text-align: center;
    a {
        color: ${colors.orange};
    }
    p {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 30px;
    }
`;

const ErrorCode = styled.div`
    text-align: center;
    font-size: 200px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 80px;
    @media (max-width: 767px) {
        font-size: 50px;
        margin-bottom: 40px;
    }
`;
