import styled, { css } from 'styled-components';
import { colors, effects } from '/css';
import React, { useMemo } from 'react';
import { InputIconAlignment, InputSizes } from './Input.types';
import InputSuffix from './input/Input.suffix';
import FormControl from './FormControl';

export interface InputProps
    extends Omit<
        React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
        'onChange' | 'size' | 'prefix' | 'onSubmit'
    > {
    multiline?: boolean;
    icon?: React.ReactNode;
    onChange: (value: string) => void;
    invalid?: boolean;
    valid?: boolean;
    size?: InputSizes;
    iconAlignment?: InputIconAlignment;
    optional?: boolean;
    className?: string;
    clearable?: boolean | 'always';
    suffix?: string;
    prefix?: string;
    label?: string;
    errors?: string[];
    flat?: boolean;
    boxless?: boolean;
    bordered?: boolean;
    onClear?: () => void;
    regex?: string;
    disabled?: boolean;
    onSubmit?: (val: string) => void;
}

interface DisabledProps extends Omit<InputProps, 'onChange'> {
    disabled: true;
    onChange?: never;
}

const Input = ({
    multiline,
    icon,
    invalid,
    valid,
    size = InputSizes.REGULAR,
    suffix,
    clearable,
    optional = false,
    iconAlignment = InputIconAlignment.LEFT,
    className,
    label,
    errors = [],
    onChange,
    onClear,
    prefix,
    boxless,
    bordered,
    flat = false,
    regex,
    disabled,
    onSubmit,
    ...rest
}: InputProps | DisabledProps) => {
    const handleInputChange = (
        e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const target = e.target as HTMLInputElement | HTMLTextAreaElement;

        if (regex && !new RegExp(regex).test(target.value)) {
            return;
        }
        onChange?.(target.value);
    };

    const isInvalid = useMemo(() => {
        return !!invalid || errors.length > 0;
    }, [invalid, errors]);

    const submitHandlerProp = onSubmit
        ? {
              onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                      onSubmit(e.currentTarget.value);
                  }
              },
          }
        : {};

    const handleClear = () => {
        onChange?.('');
        onClear?.();
    };

    return (
        <FormControl label={label} errors={errors}>
            <Component
                invalid={isInvalid}
                valid={!!valid}
                size={size}
                iconAlignment={iconAlignment}
                className={className}
                readOnly={!!rest.readOnly}
                hasSuffix={!!suffix}
                hasIcon={!!icon}
                hasPrefix={!!prefix}
                flat={!!(flat || disabled)}
                boxless={boxless}
                bordered={bordered}
            >
                {!!icon && <div className="icon-wrap">{icon}</div>}
                <InputEl
                    suppressHydrationWarning
                    as={multiline ? 'textarea' : 'input'}
                    onInput={handleInputChange}
                    disabled={disabled}
                    {...submitHandlerProp}
                    {...rest}
                />

                <InputSuffix
                    invalid={isInvalid}
                    valid={valid}
                    readOnly={!!rest.readOnly}
                    clearable={clearable}
                    suffix={suffix}
                    value={rest.value}
                    onClear={handleClear}
                />
                {!!prefix && <div className="prefix">{prefix}</div>}
            </Component>
        </FormControl>
    );
};

const InputEl = styled.input``;

const Component = styled.div<{
    invalid: boolean;
    valid: boolean;
    size: InputSizes;
    iconAlignment: InputIconAlignment;
    readOnly: boolean;
    hasSuffix: boolean;
    hasPrefix: boolean;
    hasIcon: boolean;
    flat: boolean;
    boxless?: boolean;
    bordered?: boolean;
}>`
    position: relative;

    .icon-wrap {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        svg {
            display: block;
        }

        ${(p) =>
            p.iconAlignment === InputIconAlignment.LEFT &&
            css`
                left: 16px;
            `}
        ${(p) =>
            p.iconAlignment === InputIconAlignment.RIGHT &&
            css`
                right: 16px;
            `}
    }

    .prefix {
        font-size: 17px;
        font-family: var(--font-semibold);
        position: absolute;
        top: 49%;
        transform: translateY(-50%);
        left: 16px;

        ${(p) =>
            p.readOnly &&
            css`
                color: ${colors.grey};
            `}
    }

    input,
    textarea {
        height: 54px;
        border: 3px solid white;
        background: #fff;
        outline: none;
        border-radius: 12px;
        ${effects.shadow};
        padding: 12px 16px 15px;
        font-size: 17px;
        color: ${colors.black};

        &::placeholder {
            color: ${colors.grey};
            opacity: 1;
        }
        &:focus {
            border: 3px solid #7e69fd;
        }
        &:hover {
            ${effects.shadowHover};
        }

        &:read-only {
            opacity: 1;
            color: ${colors.grey};
            &:hover {
                ${effects.shadow};
            }
            &:focus {
                border: 3px solid #fff;
            }
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        transition: all 0.2s ease-in-out;

        ${(p) =>
            p.invalid &&
            css`
                border: 3px solid ${colors.orange};
            `}

        ${(p) =>
            p.valid &&
            css`
                border: 3px solid ${colors.green};
            `}

        ${(p) =>
            p.hasIcon &&
            p.iconAlignment === InputIconAlignment.LEFT &&
            css`
                padding-left: 48px;
            `}

        ${(p) =>
            p.hasIcon &&
            p.iconAlignment === InputIconAlignment.RIGHT &&
            css`
                padding-right: 48px;
            `}

        ${(p) =>
            p.hasSuffix &&
            css`
                padding-right: 70px;
            `}

        ${(p) =>
            p.hasPrefix &&
            css`
                padding-left: 50px;
            `}
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
    textarea {
        min-height: 168px;
        line-height: 1.3;
    }

    ${(p) =>
        p.size === InputSizes.SMALL &&
        css`
            input,
            textarea {
                font-size: 17px !important;
                line-height: 1.3;
            }

            input {
                height: 36px;
                padding: 5px 12px 5px;
            }

            textarea {
                min-height: 100px;
                height: 100px;
                padding: 5px 12px 5px;
            }
        `}
    ${(p) =>
        p.size === InputSizes.SMALL &&
        p.hasIcon &&
        css`
            input,
            textArea {
                padding-left: 38px;
            }
        `}

    ${(p) =>
        p.size === InputSizes.LARGE &&
        css`
            input,
            textarea {
                font-size: 24px;
            }

            input {
                height: 58px;
                padding: 12px 16px 12px;
            }

            textarea {
                min-height: 168px;
                height: 168px;
                padding: 12px 16px 12px;
            }
        `}

    ${(p) =>
        p.size === InputSizes.LARGE &&
        p.hasIcon &&
        css`
            input,
            textArea {
                padding-left: 58px;
            }

            .icon-wrap {
                padding-top: 3px;
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        `}

    ${(p) =>
        p.flat &&
        css`
            input,
            textarea {
                background-color: ${colors.grey2};
                border: 3px solid ${colors.grey2};
                ${effects.shadowReset};

                &:hover,
                &:read-only:hover {
                    ${effects.shadowReset};
                }
            }
        `};

    ${(p) =>
        p.bordered &&
        css`
            input,
            textarea {
                background-color: white;
                ${effects.shadowReset};
                border: 3px solid ${colors.grey2};

                &:hover,
                &:read-only:hover {
                    ${effects.shadowReset};
                }

                &:hover {
                    border: 3px solid ${colors.purple};
                }
            }
        `}

    ${(p) =>
        p.boxless &&
        css`
            input,
            textarea {
                border: none;
                border-radius: 0;
                ${effects.shadowReset};

                &:hover,
                &:read-only:hover,
                &:focus,
                &:active {
                    ${effects.shadowReset};
                    border: none;
                }
            }
        `};

    ${(p) =>
        p.boxless &&
        p.size === InputSizes.LARGE &&
        p.hasIcon &&
        css`
            .icon-wrap {
                left: 0 !important;
            }

            input {
                padding-left: 38px;
            }
        `};
`;

export default Input;
