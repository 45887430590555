import { createContext, useContext, useState } from 'react';

interface LoginModalContextType {
    open: boolean;
    message?: string;
    show: (message?: string) => void;
    hide: () => void;
}

const LoginModalContext = createContext<LoginModalContextType>({
    open: false,
    show: () => null,
    hide: () => null,
});

export const useLoginModal = () => useContext(LoginModalContext);

export default LoginModalContext;
