import isServer from '/lib/utils/isServer';
import cookie from '/lib/utils/cookie';
import isSupported from '/lib/utils/isSupported';
import camelcaseKeys from '/lib/utils/camelcaseKeys';
import { ApiErrorCodes } from '/types/apiResponseTypes';

const api = {
    authHeader: (token) => {
        const _token = token || cookie.get('AuthToken');
        return _token ? { Authorization: 'Bearer ' + _token } : {};
    },

    checkheaders: (headers) => {
        if (!isSupported(() => window.localStorage)) return;

        if (!isServer && typeof Storage !== 'undefined') {
            const currentAppVersion = headers.get('x-app-version');
            const currentCartVersion = headers.get('x-cart-version');
            const savedAppVersion =
                window.localStorage.getItem('spoton-app-version');
            const savedCartVersion = window.localStorage.getItem(
                'spoton-cart-version'
            );
            const appVersionOutdated =
                currentAppVersion && savedAppVersion !== currentAppVersion;
            const cartVersionOutdated =
                currentCartVersion && savedCartVersion !== currentCartVersion;

            if (!savedAppVersion || !savedCartVersion) {
                if (currentAppVersion)
                    window.localStorage.setItem(
                        'spoton-app-version',
                        currentAppVersion
                    );
                if (currentCartVersion)
                    window.localStorage.setItem(
                        'spoton-cart-version',
                        currentCartVersion
                    );
                return;
            }

            if (cartVersionOutdated || appVersionOutdated) {
                window.localStorage.setItem(
                    'spoton-app-version',
                    currentAppVersion
                );
                window.localStorage.setItem(
                    'spoton-cart-version',
                    currentCartVersion
                );
                if (appVersionOutdated)
                    window.localStorage.setItem(
                        'spoton-app-version-updated',
                        1
                    );
                if (cartVersionOutdated)
                    window.localStorage.setItem(
                        'spoton-cart-version-updated',
                        1
                    );
                window.location.reload();
            }
        }
    },

    fetch: async (
        endpoint,
        fetchOptions = {},
        noRedirect = false,
        isBlob = false
    ) => {
        let url = endpoint.startsWith('http')
            ? endpoint
            : `${process.env.NEXT_PUBLIC_API_BASE}/api/${endpoint}`;

        // maintenance lock
        if (
            !isServer &&
            process.env.NEXT_PUBLIC_MAINTENANCE === '1' &&
            cookie.get('maintenance') !== 'false'
        ) {
            window.location.reload();
            return { error: 'Maintenance!' };
        }

        let resp;
        try {
            if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
                fetchOptions.headers = {
                    ...fetchOptions.headers,
                    'ngrok-skip-browser-warning': 'true',
                };
            }

            resp = await fetch(url, {
                ...fetchOptions,
                headers: {
                    'Page-URL': isServer ? '' : window.location.href,
                    'Request-from': isServer ? 'server' : 'client',
                    ...fetchOptions.headers,
                },
            });
        } catch (e) {
            return { error: e };
        }

        if (resp.status === 401 && !isServer) {
            localStorage.removeItem('user');
            cookie.set('AuthToken', '', 0);
            cookie.set('ContractorUuid', '', 0);
            if (!noRedirect) window.location.reload();
            return {
                error: 'Unauthorized',
                httpStatus: 401,
                code: ApiErrorCodes.UNAUTHORIZED,
            };
        }

        api.checkheaders(resp.headers);

        try {
            if (isBlob) {
                const blob = await resp.blob();
                return blob;
            }

            const responseBody = camelcaseKeys(await resp.json(), {
                deep: true,
                exclude: ['_key', '_type'],
            });

            if (responseBody.errors) {
                return {
                    error: Object.keys(responseBody.errors)
                        .map((key) => responseBody.errors[key])
                        .join('\n'),
                    errors: responseBody.errors,
                    httpStatus: resp.status,
                    code: resp.status,
                };
            }

            if (resp.status === 422 && responseBody.message) {
                return {
                    error: responseBody.message,
                    code: resp.status,
                    errorCode: responseBody.code,
                };
            }

            if (!resp.ok && responseBody.message) {
                return {
                    error: responseBody.message,
                    code: resp.status,
                    errorCode: responseBody.code,
                };
            }

            if (!resp.ok) {
                return { error: resp.statusText };
            }

            return responseBody;
        } catch (e) {
            console.log(
                'API CALL: ',
                url,
                fetchOptions,
                'Unable to parse the API response',
                resp
            );

            return resp.status === 200
                ? { status: 'ok' }
                : { error: 'Unable to parse the API response' };
        }
    },

    request: async (
        method,
        endpoint,
        data,
        headers,
        noRedirect = false,
        isBlob = false
    ) => {
        return api.fetch(
            endpoint,
            {
                method: method,
                body: JSON.stringify(data),
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
            },
            noRedirect,
            isBlob
        );
    },

    /*
    |--------------------------------------------------------------------------
    |  Generic GET request
    |--------------------------------------------------------------------------
    */
    get: async (endpoint) => {
        return api.fetch(endpoint);
    },
    getSecure: async (endpoint, token, headers = {}) => {
        return api.fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...api.authHeader(token),
                ...headers,
            },
        });
    },

    /*
    |--------------------------------------------------------------------------
    |  Generic POST request
    |--------------------------------------------------------------------------
    */
    post: async (
        endpoint,
        data = {},
        headers = api.authHeader(),
        noRedirect = false
    ) => {
        const isBlob = headers?.responseType === 'blob';
        return api.request('POST', endpoint, data, headers, noRedirect, isBlob);
    },

    /*
    |--------------------------------------------------------------------------
    |  Generic PATCH request
    |--------------------------------------------------------------------------
    */
    patch: async (endpoint, data = {}, headers = api.authHeader()) => {
        return api.request('PATCH', endpoint, data, headers);
    },

    /*
    |--------------------------------------------------------------------------
    |  Generic DELETE request
    |--------------------------------------------------------------------------
    */
    delete: async (endpoint, data = {}, headers = api.authHeader()) => {
        return api.request('DELETE', endpoint, data, headers);
    },
};

export default api;
