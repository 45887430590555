import { percentOf, objectToQuery } from '/front/lib/helpers';
import ImageType from '../types/frontImageType';

class ImageUrlBuilder {
    private w: number = 1120;
    private h: string | number = '';
    private auto: string = 'format';
    private fpx: number | string = '';
    private fpy: number | string = '';
    private fpz: number | string = '';
    private crop: string = '';
    private fit: string = '';
    private fpDebug: boolean = false;
    private dpr: string = '';
    private placeholderUrl: string = 'https://dummyimage.com/';
    private image?: ImageType;

    constructor(image: ImageType | null) {
        if (image) {
            this.image = image;
        }
    }

    debug(bool: boolean): ImageUrlBuilder {
        this.fpDebug = bool;
        return this;
    }

    applyCrop(): ImageUrlBuilder {
        if (!this.image || !this.image.crop || !this.image.metadata?.dimensions)
            return this;

        const { top, left, right, bottom } = this.image.crop;
        const { width: originalWidth, height: originalHeight } =
            this.image.metadata.dimensions;

        const cropWidth = originalWidth * (1 - left - right);
        const cropHeight = originalHeight * (1 - top - bottom);

        const isVertical = cropHeight > cropWidth;

        this.fpx = left + (1 - left - right) / 2;
        this.fpy = top + (1 - top - bottom) / 2;
        this.h = this.h || (percentOf(this.w, cropWidth) / 100) * cropHeight;
        this.fpz = isVertical
            ? originalHeight / cropHeight
            : originalWidth / cropWidth;
        this.crop = 'focalpoint';
        this.fit = 'crop';

        return this;
    }

    width(width: number): ImageUrlBuilder {
        this.w = width;
        this.applyCrop();
        return this;
    }

    height(height: number): ImageUrlBuilder {
        this.h = height;
        this.applyCrop();
        return this;
    }

    ratio(ratio: string): ImageUrlBuilder {
        this.dpr = ratio;
        return this;
    }

    url(): string {
        const params: Record<string, string | number | boolean> = {
            w: this.w,
            h: this.h,
            auto: this.auto,
            'fp-x': this.fpx,
            'fp-y': this.fpy,
            'fp-z': this.fpz,
            'fp-debug': this.fpDebug,
            crop: this.crop,
            fit: this.fit,
        };

        if (this.dpr) {
            params.dpr = this.dpr;
        }

        if (this.image) {
            return `${this.image.url}?${objectToQuery(params)}`;
        }

        return `${this.placeholderUrl}${this.w}x${
            this.h || this.w
        }/CCCCCC/696969`;
    }
}

export default ImageUrlBuilder;
