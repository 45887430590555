import styled from 'styled-components';
import Button from './Button';
import { IconButtonProps } from './Button.types';

const IconButton = ({ icon, ...rest }: IconButtonProps) => {
    return <StyledButton icon={icon} {...rest} />;
};

const StyledButton = styled(Button)`
    padding: 0 8px 1px;
    .button-icon {
        margin-left: 0;
        margin-right: 0;
    }
`;

export default IconButton;
