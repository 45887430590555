export enum InputSizes {
    SMALL = 'small',
    REGULAR = 'regular',
    LARGE = 'large',
}

export enum InputIconAlignment {
    LEFT = 'left',
    RIGHT = 'right',
}

export interface InputValidationRules {
    integer?: boolean;
    float?: boolean;
    maxLength?: number;
    max?: number;
    min?: number;
    phone?: boolean;
    email?: boolean;
    required?: boolean;
    regexp?: RegExp;
}
