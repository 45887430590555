import moment from 'moment';
import capitalizeFirstFunc from './capitalizeFirst';

interface Config {
    includeDay?: boolean;
    capitalizeFirst?: boolean;
    shortMonth?: boolean;
}

const defaultConfig: Config = {
    includeDay: false,
    capitalizeFirst: false,
    shortMonth: false,
};

export const formatDateTime = (
    dateString: string,
    config: boolean | Config = false
) => {
    if (!dateString) return '';

    const { includeDay, capitalizeFirst, shortMonth }: Config = {
        ...defaultConfig,
        ...(typeof config === 'boolean'
            ? {
                  includeDay: config,
              }
            : config),
    };

    const date = moment(dateString);
    const formattedDay = includeDay ? `${date.format('dddd')} ` : '';
    const formattedDate = shortMonth
        ? date.format('DD. MMM')
        : date.format('DD. MMMM');
    const formattedTime = date.format('HH:mm');

    const fullString = `${formattedDay}${formattedDate} • kl. ${formattedTime}`;

    return capitalizeFirst ? capitalizeFirstFunc(fullString) : fullString;
};

export default formatDateTime;
