import { colors } from '/css';

const TriangleExclamationSolid = ({
    color = colors.black,
    size = 18,
    ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
        <g clipPath="url(#clip0_5049_24684)">
            <path
                d="M8.99992 1.125C9.49914 1.125 9.95969 1.38867 10.2128 1.82109L17.8066 14.7586C18.0632 15.1945 18.0632 15.7324 17.8136 16.1684C17.564 16.6043 17.0964 16.875 16.5937 16.875H1.40617C0.903439 16.875 0.435861 16.6043 0.186251 16.1684C-0.0633582 15.7324 -0.0598425 15.191 0.193282 14.7586L7.78703 1.82109C8.04016 1.38867 8.50071 1.125 8.99992 1.125ZM8.99992 5.625C8.53235 5.625 8.15617 6.00117 8.15617 6.46875V10.4062C8.15617 10.8738 8.53235 11.25 8.99992 11.25C9.4675 11.25 9.84367 10.8738 9.84367 10.4062V6.46875C9.84367 6.00117 9.4675 5.625 8.99992 5.625ZM10.1249 13.5C10.1249 13.2016 10.0064 12.9155 9.79542 12.7045C9.58444 12.4935 9.29829 12.375 8.99992 12.375C8.70156 12.375 8.41541 12.4935 8.20443 12.7045C7.99345 12.9155 7.87492 13.2016 7.87492 13.5C7.87492 13.7984 7.99345 14.0845 8.20443 14.2955C8.41541 14.5065 8.70156 14.625 8.99992 14.625C9.29829 14.625 9.58444 14.5065 9.79542 14.2955C10.0064 14.0845 10.1249 13.7984 10.1249 13.5Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_5049_24684">
                <rect width={size} height={size} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default TriangleExclamationSolid;
