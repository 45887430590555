import snakecaseKeys from 'snakecase-keys';

import settingsApi from '/api/settings';
import { Error } from '/front/types/frontApiType';
import Settings from '/front/types/frontSettingsType';

const frontSettingsApi = {
    get: async (): Promise<Settings | Error> => {
        const resp = (await settingsApi.all()) as Settings | Error;

        if ('error' in resp) return { error: resp.error };

        return resp;
    },
    patch: async (settings: Settings): Promise<Settings | Error> => {
        const resp = (await settingsApi.update(snakecaseKeys(settings))) as
            | Settings
            | Error;

        if ('error' in resp) return { error: resp.error };

        return resp;
    },
};

export default frontSettingsApi;
