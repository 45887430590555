import api from './api';
import { EmailSubscriptionType } from '/types/EmailSubscriptionType';

export default {
    checkPostCode: (code) => api.get('post-code/verify/' + code),

    getPostCodeInfo: (code) => api.get('post-code/' + code),

    /** TODO: Remove this, trust me, just do it. */
    saveEmail: ({ post_code, email, name = '', notification_types }) =>
        api.post('post-code/email-notification', {
            post_code,
            email,
            name,
            notification_types,
        }),

    saveNoSlotsEmail: ({ email, post_code, work_hours }) =>
        api.post('no-availability', { email, post_code, work_hours }),
};
