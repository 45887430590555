import styled from 'styled-components';

import Icon from '/components/ui/Icon';
import { colors } from '/css';

const ErrorComponent = ({ children, className = '', style = {} }) => {
    if (!children) return null;

    const error = typeof children === 'string' ? children : 'Error';

    return (
        <>
            {error.split('\n').map((e, i) => (
                <ErrorEl key={i} className={className} style={style}>
                    <Icon icon="error" fill={colors.orange} /> <span>{e}</span>
                </ErrorEl>
            ))}
        </>
    );
};

export default ErrorComponent;

const ErrorEl = styled.div`
    background: ${colors.orangeLighter};
    color: ${colors.orange};
    padding: 16px 18px;
    border-radius: 12px;
    ${(props) => (!props.className ? 'margin-bottom: 20px;' : '')}
    display: flex;
    align-items: center;
    svg {
        margin-right: 6px;
        min-width: 24px;
    }
`;
