import { MdOutlineClear } from 'react-icons/md';

import DevSignInOptions from '/components/DevSignInOptions';

export default function FrontCustomSiteControlls() {
    const clearStorage = () => {
        localStorage.clear();
        location.reload();
    };

    const clearCookies = () => {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const cName = (cookie.split('=')[0] || '').trim();
            document.cookie = `${cName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        }
        location.reload();
    };

    const showDevControlls =
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' ||
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging';

    if (!showDevControlls) return null;

    return (
        <>
            <DevSignInOptions />
            <li>
                <MdOutlineClear />
                Clear site data
                <ul>
                    <li onClick={clearCookies}>Clear cookies</li>
                    <li onClick={clearStorage}>Clear local storage</li>
                </ul>
            </li>
        </>
    );
}
